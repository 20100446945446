import { call, put, takeLatest } from "redux-saga/effects";
import * as GuideService from "../../services/guide-service";
import { GuideFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  searchGuideSuccess,
  hotGuidesSuccess,
  highGuidesSuccess,
  getGuideByIdSuccess,
  getGuideSuccess,
  highServicesSuccess,
  getPolicySuccess,
  failure
} from "./reducer";

export function* fetchGuides({ payload = {} }) {
  try {
    const { data } = yield call(GuideService.searchGuide, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchGuideSuccess({ guides: results, pagination: pagination }));
    } else {
      yield put(searchGuideSuccess({ guides: [], pagination: { pages: 1, page: 50, total: 0 }}))
    }
  } catch (error) {
    yield put(searchGuideSuccess({ guides: [], pagination: { pages: 1, page: 50, total: 0 }}))
  }
}

export function* fetchHotGuides({ payload = {} }) {
  try {
    const { data } = yield call(GuideService.searchGuide, payload);
    if (data) {
      const { results } = data;
      yield put(hotGuidesSuccess({ guides: results }));
    } else {
      yield put(hotGuidesSuccess({ guides: [] }));
    }
  } catch (error) {
    yield put(hotGuidesSuccess({ guides: [] }));
  }
}

export function* fetchHighGuides({ payload = {} }) {
  try {
    const { data } = yield call(GuideService.searchGuide, payload);
    if (data) {
      const { results } = data;
      yield put(highGuidesSuccess({ guides: results }));
    } else {
      yield put(highGuidesSuccess({ guides: [] }));
    }
  } catch (error) {
    yield put(highGuidesSuccess({ guides: [] }));
  }
}

export function* fetchHighServices({ payload = {} }) {
  try {
    const { data } = yield call(GuideService.searchServices, payload);
    if (data) {
      const { results } = data;
      yield put(highServicesSuccess({ guides: results }));
    } else {
      yield put(highServicesSuccess({ guides: [] }));
    }
  } catch (error) {
    yield put(highServicesSuccess({ guides: [] }));
  }
}

export function* fetchPolicyServices({ payload = {} }) {
  try {
    const { data } = yield call(GuideService.searchPolicyServices, payload);
    if (data) {
      const { results } = data;
      yield put(getPolicySuccess({ guides: results }));
    } else {
      yield put(getPolicySuccess({ guides: [] }));
    }
  } catch (error) {
    yield put(getPolicySuccess({ guides: [] }));
  }
}

export function* getGuideById({ payload = {} }) {
  try {
    const { data } = yield call(GuideService.getGuide, payload);
    if (data) {
      yield put(getGuideByIdSuccess({ guide: data }));
    } else {
      yield put(getGuideByIdSuccess({ guide: data }));
    }
  } catch (error) {
    yield put(getGuideByIdSuccess({ guide: null }));
  }
}

export function* getGuide({ payload }) {
  try {
    if(payload) {
      const { data } = yield call(GuideService.getGuideBySlug, payload);
      if (data) {
        yield put(getGuideSuccess({ guide: data }));
      } else {
        yield put(getGuideSuccess({ guide: null }));
      }
    } else {
      yield put(getGuideSuccess({ guide: null }));
    }
  } catch (error) {
    yield put(getGuideSuccess({ guide: null }));
  }
}

export function* saveGuide({ payload }) {
  try {
    let result;
    if (payload._id) {
      result = yield call(GuideService.updateGuide, {
        guide: payload,
        id: payload._id
      });
    } else {
      result = yield call(GuideService.createGuide, payload);
    }

    if (result) {
      yield put(GuideFormAction.success("Lưu thành công"));
    } else {
      const formError = new SubmissionError({
        _error: 'Lưu bài viêt thất bại'
      });
      yield put(GuideFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error?.message || 'Lưu bài viêt thất bại'
    });
    yield put(GuideFormAction.failure(formError));
  }
}

export function* deleteGuide({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(GuideService.deleteGuide, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(GuideFormAction.REQUEST, saveGuide),
  takeLatest("GUIDES_LIST_SEARCH_REQUEST", fetchGuides),
  takeLatest("GUIDES_LIST_HOT_REQUEST", fetchHotGuides),
  takeLatest("GUIDES_LIST_HIGH_REQUEST", fetchHighGuides),
  takeLatest("SERVICE_LIST_HOT_REQUEST", fetchHighServices),
  takeLatest("GET_POLICY_SERVICE_REQUEST", fetchPolicyServices),
  takeLatest("GET_GUIDE_BY_SLUG_REQUEST", getGuide),
  takeLatest("GET_GUIDE_BY_ID_REQUEST", getGuideById),
  takeLatest("GUIDE_DELETE_REQUEST", deleteGuide)
];
