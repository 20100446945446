import { call, put, takeLatest } from 'redux-saga/effects';
import * as keywordService from '../../services/keyword-service';

import { KeywordFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  searchKeywordsSuccess,
  failure,
} from './reducer';


export function* fetchKeywords({ payload = {} }) {
  try {
    const { data } = yield call(keywordService.searchKeywords, payload);
    if(data) {
      const { results, pagination} = data;
      yield put(searchKeywordsSuccess({ keywords: results, pagination }));
    } else {
      yield put(searchKeywordsSuccess({ keywords: [], pagination: { size: 1, page: 50, total: 0 } }));
    }
  } catch (error) {
    yield put(searchKeywordsSuccess({ keywords: [], pagination: { size: 1, page: 50, total: 0 } }));
  }
}

export function* saveKeywords({ payload }) {
  try {
      let result;
      if (payload.id) {
        result = yield call(keywordService.updateKeyword, payload);
      } else {
        result = yield call(keywordService.createKeyword, payload);
      }
    if(result) {
      yield put(KeywordFormAction.success(result))
    } else {
      throw new Error('Lưu từ khóa thất bại!')
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Save failed'
    })

    yield put(KeywordFormAction.failure(formError))
  }
}

export function* updateKeyword({ payload }) {
  const { body, cb } = payload;
  try {
    yield call(keywordService.updateKeyword, body);
    if(cb) cb(true);
  } catch (error) {
    if(cb) cb(false);
  }
}

export function* copyKeywords({ payload }) {
  const { body, cb } = payload;
  try {
    const {code, message} = yield call(keywordService.copyKeyword, body);
    if(code) {
      cb(true, message);
    } else {
      cb(false, message);
    }
  } catch (error) {
    cb(false, "Lỗi sao chép từ khóa");
  }
}

export function* deleteKeywords({ payload }) {
  const { id, cb } = payload;
  try {
    const {code, message} = yield call(keywordService.deleteKeyword, id);
    if(code) {
      cb(true, message);
    } else {
      cb(false, message);
    }
  } catch (error) {
    cb(false, "Lỗi xóa từ khóa");
  }
}


export default [
  takeLatest(KeywordFormAction.REQUEST, saveKeywords),
  takeLatest('KEYWORD_LIST_SEARCH_REQUEST', fetchKeywords),
  takeLatest('UPDATE_KEYWORD_REQUEST', updateKeyword),
  takeLatest('KEYWORD_COPY_REQUEST', copyKeywords),
  takeLatest('KEYWORD_DELETE_REQUEST', deleteKeywords),
];
