import { call, put, takeLatest } from "redux-saga/effects";
import * as BrandService from "../../services/brand-service";
import { BrandFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchBrandSuccess,
  getBrandByIdSuccess,
  failure
} from "./reducer";

export function* fetchBrand({ payload }) {
  try {
    const { data } = yield call(BrandService.searchBrand, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchBrandSuccess({ brands: results, pagination }));
    } else {
      yield put(failure("Search roles failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getBrandById({ payload }) {
  try {
    const { data } = yield call(BrandService.getBrandById, payload);
    if (data) {
      yield put(getBrandByIdSuccess({ brand: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createBrand({ payload }) {
  try {
    let { data, message } = yield call(BrandService.saveBrand, payload);

    if (data) {
      yield put(BrandFormAction.success("Lưu thành công"));
    } else {
      throw new Error(message || 'Lỗi thất bại')
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.message || ' Lưu thất bại'
    });
    yield put(BrandFormAction.failure(formError));
  }
}

export function* updateBrand({ payload }) {
  const { body, cb } = payload;
  try {
    const { data } = yield call(BrandService.saveBrand, body);
    if (data) {
      cb(true);
    } else {
      cb(false);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}

export function* deleteBrand({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(BrandService.deleteBrand, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(BrandFormAction.REQUEST, createBrand),
  takeLatest("BRAND_LIST_SEARCH_REQUEST", fetchBrand),
  takeLatest("GET_BRAND_BY_ID_SEARCH_REQUEST", getBrandById),
  takeLatest("UPDATE_BRAND_REQUEST", updateBrand),
  takeLatest("DELETE_BRAND_REQUEST", deleteBrand),
];
