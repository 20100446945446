
import axios from '../utils/axiosInstance';

export const autoCompleteCustomer = async (params = {}) => {
  try {
    const { data } = await axios.get('/crm/autocomplete/customer', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const autoCompleteConsignee = async (params = {}) => {
  try {
    const { data } = await axios.get('/fin-autocomplete-agent/autocomplete-consigneeinfo', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getServiceType = async (params = {}) => {
  try {
    const { data } = await axios.get('/common/service-type', { params: params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getExtraServiceType = async (params = {}) => {
  try {
    const { data } = await axios.get('/common/extra-service-type');
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCurrency = async (params = {}) => {
  try {
    const { data } = await axios.get('/common/currency', { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const fetchCalculater = async (params = {}) => {
  try {
    const _params = {
      'ServiceCode': params.ServiceCode,
      'ToCountryOrTerritoryId': params.CountryId,
      'PackageType': params.PackageType || 'SPX',
      'GoodsValue': params.GoodsValue || 0,
      'NumberOfItems': params.NumberOfItems || 1,
      'Weight': params.Weight,
      'Dim.Length': params.Length,
      'Dim.Width': params.Width,
      'Dim.Height': params.Height,
      'AccountNr': params.AccountNr || '',
      'OtherFees': params.OtherFees || 0,
      'ChildItems': params.ChildItems || []
    }

    let str = '';
    if(_params && _params.ChildItems && _params.ChildItems.length) {
      _params.ChildItems.forEach((item, index) => {
        if(str) str += `&ChildItems[${index}].Weight=${item.Weight || 0}&ChildItems[${index}].Length=${item.Length || 0}&ChildItems[${index}].Width=${item.Width || 0}&ChildItems[${index}].Height=${item.Height || 0}`
        else str += `ChildItems[${index}].Weight=${item.Weight || 0}&ChildItems[${index}].Length=${item.Length || 0}&ChildItems[${index}].Width=${item.Width || 0}&ChildItems[${index}].Height=${item.Height || 0}`;
      });
    }
    delete _params['ChildItems'];
    const { data } = await axios.get(`/api/international-price-calculator?${str}`, { params: _params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCalculaterExtra = async (params = {}) => {
  try {
    let str = ''
    if(params.services && params.services.length) {
      params.services.forEach(id => {
        str += `&ExtraService=${id}`
      });
      const { data } = await axios.get(`/bill/calc-price-extra-service?${str}`, { params });
      return data;
    } else {
      return { Result: 0 }
    }
   
  } catch (error) {
    throw error;
  }
};




