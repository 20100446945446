import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchSizeRequest = createAction("SIZE_LIST_SEARCH_REQUEST");
export const searchSizeSuccess = createAction("SIZE_LIST_SEARCH_SUCCESS");

export const getSizeByIdRequest = createAction("GET_SIZE_BY_ID_SEARCH_REQUEST");
export const getSizeByIdSuccess = createAction("GET_SIZE_BY_ID_SEARCH_SUCCESS");

export const updateSizeRequest = createAction("UPDATE_SIZE_REQUEST");
export const updateSizeSuccess = createAction("UPDATE_SIZE_SUCCESS");

export const deleteSizeRequest = createAction("DELETE_SIZE_REQUEST");

export const failure = createAction("SIZE_ACTION_FAILURE");

// - Initial State
export const initialState = {
  sizes: [],
  pagination: {
    pages: 1,
    page: 20,
    total: 0
  },
  loading: false,
  size: null,
  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchSizeRequest]: state => ({
      ...state,
      loading: true
    }),
    [searchSizeSuccess]: (state, { payload: { sizes, pagination } }) => ({
      ...state,
      sizes: sizes,
      pagination: pagination,
      loading: false
    }),

    [getSizeByIdSuccess]: (state, { payload: { size } }) => ({
      ...state,
      size: size,
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
