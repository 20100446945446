import axios from "../utils/axiosInstance";

export const getUserByToken = async (id) => {
  try {
    const { data } = await axios.get("/api/session");
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAvatarById = async (AccountNr) => {
  try {
    const { data } = await axios.get(`/api/user-info/${AccountNr}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateProfile = async ({ id, user }) => {
  try {
    const { data } = await axios.put(`/api/user/${id}`, user);
    return data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (params) => {
  try {
    const { data } = await axios.post(`/api/auth/password/change`, params);
    return data;
  } catch (error) {
    throw error;
  }
};
