import Cookies from 'js-cookie';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../config/constants';

class Auth {

  static setAccessToken (data) {
    Cookies.set(ACCESS_TOKEN_KEY, data.token, { expires: new Date(data.expires) });
  }

  static setRefreshToken (data) {
    Cookies.set(REFRESH_TOKEN_KEY, data.token, { expires: new Date(data.expires) });
  }

  static isUserAuthenticated () {
    const token = Cookies.get(ACCESS_TOKEN_KEY);
    if (!token) return false;
    return true;
  }

  static deauthenticateUser () {
    Cookies.remove(ACCESS_TOKEN_KEY);
    Cookies.remove(REFRESH_TOKEN_KEY);
  }

  static getAccessToken() {
    return Cookies.get(ACCESS_TOKEN_KEY);
  }
  static getRefreshToken() {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  static getUser () {
    try {
      const token = this.getAccessToken();
      return token;
    } catch (error) {
      return null;
    }
  }
}

export default Auth
