import { createAction, handleActions } from 'redux-actions';
// - Actions
export const searchQuickRequest = createAction('QUICK_SEARCH_REQUEST');
export const searchQuickSuccess = createAction('QUICK_SEARCH_SUCCESS');
export const deleteQuickRequest = createAction('QUICK_DELETE_REQUEST');

export const failure = createAction('QUICK_ACTION_FAILURE');

// - Initial State
export const initialState = {
  quicks: [],
  quicksing: false,
  pagination: {
    size: 1,
    page: 50,
    total: 0
  },
};

// - Reducers
const reducer = handleActions({
  [searchQuickRequest]: (state) => {
    return { ...state, loading: true, };
  },
  [searchQuickSuccess]: (state, { payload: { quicks, pagination } }) => { // eslint-disable-line
    return { ...state, quicks: quicks, pagination, loading: false, };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loadingFetch: false,
    loading: false,
  }),
}, initialState);

export default reducer;
