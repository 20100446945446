import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchBrandRequest = createAction("BRAND_LIST_SEARCH_REQUEST");
export const searchBrandSuccess = createAction("BRAND_LIST_SEARCH_SUCCESS");

export const getBrandByIdRequest = createAction("GET_BRAND_BY_ID_SEARCH_REQUEST");
export const getBrandByIdSuccess = createAction("GET_BRAND_BY_ID_SEARCH_SUCCESS");

export const updateBrandRequest = createAction("UPDATE_BRAND_REQUEST");
export const updateBrandSuccess = createAction("UPDATE_BRAND_SUCCESS");

export const deleteBrandRequest = createAction("DELETE_BRAND_REQUEST");

export const failure = createAction("BRAND_ACTION_FAILURE");

// - Initial State
export const initialState = {
  brands: [],
  pagination: {
    pages: 1,
    page: 20,
    total: 0
  },
  loading: false,
  brand: null,
  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchBrandRequest]: state => ({
      ...state,
      loading: true
    }),
    [searchBrandSuccess]: (state, { payload: { brands, pagination } }) => ({
      ...state,
      brands: brands,
      pagination: pagination,
      loading: false
    }),

    [getBrandByIdSuccess]: (state, { payload: { brand } }) => ({
      ...state,
      brand: brand,
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
