import { call, put, takeLatest } from 'redux-saga/effects';
import * as storeService from '../../services/system-service';
import { SystemFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  getSystemSuccess,
  failure,
} from './reducer';


export function* getSystem({ payload = {} }) {
  try {
    const { data } = yield call(storeService.getSystem, payload);
    if(data) {
      yield put(getSystemSuccess({ system: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* saveSystem({ payload }) {
  try {
    let result = yield call(storeService.updateStore, payload);
    if (result) {
      yield put(SystemFormAction.success('Lưu thành công'))
    } else {
      const { message } = 'Lưu thành thất bại!'
      const formError = new SubmissionError({
        _error: message
      })
      yield put(SystemFormAction.failure(formError))
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Save failed'
    })

    yield put(SystemFormAction.failure(formError))
  }
}


export default [
  takeLatest(SystemFormAction.REQUEST, saveSystem),
  takeLatest('GET_SYSTEM_REQUEST', getSystem),
];
