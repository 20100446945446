import vi from './svg/flags/vietnam.svg';
import en from'./svg/flags/united-kingdom.svg';
import flas_jp from'./flags/jp.svg';
import flas_vi from'./flags/vi.svg';
import flas_en from'./flags/uk.svg';


import logo from'./icons/logo.png';
import loading from'./svg/loading.svg';
import filter from'./svg/filter-results.svg';

const icons = {
  common: {
    logo: logo,
    loading: loading,
    filter: filter,
    excel: require('./svg/excel.svg'),
    excel_primary: require('./svg/excel-primary.svg'),
    more: require('./svg/more.svg'),
    exchange: require('./svg/exchange.svg'),
    email: require('./svg/email.svg'),
    sms: require('./svg/sms.svg'),

    next: require('./svg/next.svg'),

    print: require('./svg/print.svg'),
    back: require('./svg/back.svg'),
    up_arrow: require('./svg/up-arrow.svg'),
    down_arrow: require('./svg/down-arrow.svg'),
    import: require('./svg/import.svg'),
    export: require('./svg/export.svg'),
    refresh: require('./svg/refresh.svg'),
    history: require('./svg/history.svg'),
    trace: require('./svg/dang_giao.svg'),
  },
  flag: {
    jp_icon: flas_jp,
    vi_icon: flas_vi,
    en_icon: flas_en,
  },
  file: {
    // pdf: require('./images/file/pdf.svg'),
    // doc: require('./images/file/doc.svg'),
    // xlsx: require('./images/file/xls.svg'),
    // png: require('./images/file/png.svg'),
  },

  NationalFlag: {
    vi: vi,
    en: en
  }
}

const images = {
  common: {
    // noAvatar: require('./images/no-avatar.png'),
    // noImage: require('./images/no-image-big.jpg')
  },
  home: {

  },
  aboutUs: {

  }
}

export {
  icons,
  images
}
