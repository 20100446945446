import localStorage from 'localStorage'
import jwt from 'jsonwebtoken';

class Auth {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser (token) {
    localStorage.setItem('auth_token', token)
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated () {
    const token = this.getToken();
    if(token) {
      var user = jwt.decode(token);
      return (user && user.exp > 0 && Date.now() <= user.exp * 1000)
    }
    return false;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser () {
    localStorage.removeItem('auth_token')
    localStorage.removeItem('user_info')
    localStorage.removeItem('auth_store')
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken () {
    return localStorage.getItem('auth_token')
  }

  /**
   * Set userinfo. Save userinfo in Local Storage
   *
   * @param {string} user
   */
  static setUserInfo (user) {
    localStorage.setItem('user_info', user)
  }

  /**
   * Get userinfo.
   *
   * @returns {string}
   */

  static getUserInfo () {
    return localStorage.getItem('user_info')
  }

  static setNext (url) {
    localStorage.setItem('url_next', url)
  }
  
  static getNext () {
    return localStorage.getItem('url_next')
  }

  static getUser () {
    try {
      const token = this.getToken();
      // let user = localStorage.getItem('userInfo');
      var user = jwt.decode(token);
      if(user) {
        // user = JSON.parse(user.toString());
        return user;
      } 
      return null;
    } catch (error) {
      return null;
    }
  }
}

export default Auth
