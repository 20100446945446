import { call, put, takeLatest } from "redux-saga/effects";
import * as imageService from "../../services/image-service";

import { imagesListSuccess, failure } from "./reducer";

export function* fetchImages({ payload }) {
  try {
    const { data } = yield call(imageService.searchImages, payload);
    if (data) {
      yield put(imagesListSuccess({ images: data }));
    } else {
      yield put(imagesListSuccess({ images: [] }));
    }
  } catch (error) {
    yield put(imagesListSuccess({ images: [] }));
  }
}

export function* uploadImage({ payload }) {
  try {
    const result = yield call(imageService.uploadImage, payload);
    if (result) {
      // yield put(createRoleSuccess());
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* deleteImage({ payload }) {
  const { data, cb } = payload;
  try {
    const result = yield call(imageService.deleteImage, data);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export function* ceateFolderImage({ payload }) {
  const { data, cb } = payload;
  try {
    const result = yield call(imageService.ceateFolderImage, data);
    if (result.code === 1) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest("IMAGES_LIST_REQUEST", fetchImages),
  takeLatest("UPLOAD_IMAGES_REQUEST", uploadImage),
  takeLatest("DELETE_IMAGES_REQUEST", deleteImage),
  takeLatest("CREATE_FOLDER_IMAGES_REQUEST", ceateFolderImage)
];
