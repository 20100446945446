import React, { Component } from 'react';
import { Switch } from 'react-router-dom'
import routes from './routers';
import RouteSubRoutes from './RouteSubRoutes';
import ScrollTop from './ScrollTop';

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
            {routes.map((route, i) => <RouteSubRoutes key={i} {...route} />)}
        </Switch>
        <ScrollTop/>
      </div>
    )
  }
}

export default App
