import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchUsersRequest = createAction("USER_LIST_SEARCH_REQUEST");
export const searchUsersSuccess = createAction("USER_LIST_SEARCH_SUCCESS");

export const getUserRequest = createAction("GET_USER_REQUEST");
export const getUserSuccess = createAction("GET_USER_SUCCESS");

export const deleteUserRequest = createAction("DELETE_USER_REQUEST");

export const failure = createAction("USER_ACTION_FAILURE");

// - Initial State
export const initialState = {
  users: [],
  pagination: {
    pages: 1,
    page: 50,
    total: 0
  },
  loading: false,

  user: null,
  usering: false,

  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchUsersRequest]: state => ({
      ...state, loading: true
    }),
    [searchUsersSuccess]: (state, { payload: { users, pagination } }) => ({
      ...state, users: users, pagination: pagination, loading: false
    }),

    [getUserRequest]: state => ({
      ...state,
      user: null,
      usering: true
    }),
    [getUserSuccess]: (state, { payload: { user } }) => ({
      ...state,
      user: user,
      usering: false
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      contacting: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
