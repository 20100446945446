import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchUnitRequest = createAction("UNIT_LIST_SEARCH_REQUEST");
export const searchUnitSuccess = createAction("UNIT_LIST_SEARCH_SUCCESS");

export const getUnitByIdRequest = createAction("GET_UNIT_BY_ID_SEARCH_REQUEST");
export const getUnitByIdSuccess = createAction("GET_UNIT_BY_ID_SEARCH_SUCCESS");

export const updateUnitRequest = createAction("UPDATE_UNIT_REQUEST");
export const updateUnitSuccess = createAction("UPDATE_UNIT_SUCCESS");

export const deleteUnitRequest = createAction("DELETE_UNIT_REQUEST");

export const failure = createAction("UNIT_ACTION_FAILURE");

// - Initial State
export const initialState = {
  units: [],
  pagination: {
    pages: 1,
    page: 20,
    total: 0
  },
  loading: false,
  unit: null,
  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchUnitRequest]: state => ({
      ...state,
      loading: true
    }),
    [searchUnitSuccess]: (state, { payload: { units, pagination } }) => ({
      ...state,
      units: units,
      pagination: pagination,
      loading: false
    }),

    [getUnitByIdSuccess]: (state, { payload: { unit } }) => ({
      ...state,
      unit: unit,
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
