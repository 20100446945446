import axios from 'axios';
import Cookie from '../storage/cookies';
import { apiEndpoint } from '../config';
import { createDpopProof } from './dpop';

const axiosInstance = axios.create({
  baseURL: `${apiEndpoint}`,
  headers: { 'Content-Type': 'application/json' },
  // withCredentials: true,
  timeout: 600000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = Cookie.getAccessToken();
    config.headers.authorization = `Bearer ${token}`;
    config.headers.station = 'SBC_HN';
    config.headers['sbc-dpop'] = 'SBC132@!';
    // Tạo DPoP Proof và thêm vào header
    try {
      const htu = `${config.baseURL}${config.url}`;
      const htm = config.method.toUpperCase();
      const dpopProof = await createDpopProof(htu, htm);
      config.headers.DPoP = dpopProof;
    } catch (error) {
      console.error('Lỗi khi tạo DPoP Proof:', error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async (error) => {
    const prevRequest = error?.config;
    if (error.response) {
      const { data, status } = error.response;
      if (data || status) {
        if (data.message === 'FORBIDDEN' || status === 403) {
          if (!prevRequest?.sent) {
            prevRequest.sent = true;
            const access_token = await refresh();
            prevRequest.headers.authorization = `Bearer ${access_token}`;
            return axiosInstance(prevRequest);
          }
        }
        return Promise.reject(data)
      }
    }
    return Promise.reject(error)
  })

const refresh = async () => {
  const token = Cookie.getRefreshToken();
  if (token) {
    const { data } = await axiosInstance.post('/api/refresh-staff', { refresh_token: token });
    if (data.access && data.access.token) {
      Cookie.setAccessToken(data.access)
      Cookie.setRefreshToken(data.refresh)
      return data.access.token;
    } else {
      Cookie.deauthenticateUser()
    }
  }
  return null;
}

export default axiosInstance;
