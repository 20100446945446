// import registerServiceWorker from './registerServiceWorker'
import './styles/styles.less'
import './styles/styles.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd';
import App from './app'
import store from './reduxs/store';
import history from './utils/history';
import viVN from 'antd/es/locale/vi_VN';
import moment from 'moment';
import 'moment/locale/vi';

moment.locale('vi-VN');

ReactDOM.render(
  <Provider store={store}>
      <ConnectedRouter history={history}>
        <ConfigProvider locale={viVN}>
          <App />
        </ConfigProvider>
      </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// registerServiceWorker()
