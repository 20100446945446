import { call, put, takeLatest } from 'redux-saga/effects';
import * as AuthService from '../../services/authen-service';
import { LoginFormAction } from './action';
import { SubmissionError } from 'redux-form';
import Cookie from '../../storage/cookies';

export function* LoginLocal({ payload }) {
  try {
    const { access, refresh, message } = yield call(AuthService.login, payload);
    
    if(access) {
      Cookie.setAccessToken(access);
      Cookie.setRefreshToken(refresh);
      yield put(LoginFormAction.success({login: 'Login!'}));
    } else {
      let _message = message || "Đăng nhập không thành công!";
      const formError = new SubmissionError({
        _error: _message
      });
      yield put(LoginFormAction.failure(formError));
    }
  } catch (error) {
    let _message = (error && error.message) || 'Đăng nhập không thành công!'
    const formError = new SubmissionError({_error: _message});
    yield put(LoginFormAction.failure(formError));
  }
}
export default [
  takeLatest(LoginFormAction.REQUEST, LoginLocal),
];
