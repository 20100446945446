import axios from '../utils/axiosInstance';

export const findFeedbacks = async (params) => {
  try {
    const { data } = await axios.get('/api/feedbacks', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};


export const getCategoryById = async (id) => {
  try {
    const { data } = await axios.get(`/api/feedback/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createFeedback = async (feedback) => {
  try {
    const { data } = await axios.post('/api/feedback', feedback);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateFeedback = async ({feedback, id}) => {
  try {
    const { data } = await axios.put(`/api/feedback/${id}`, feedback);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteFeedback = async (id) => {
  try {
    const { data } = await axios.delete(`/api/feedback/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
