import { call, put, takeLatest } from 'redux-saga/effects';
import * as flashdealService from '../../services/flashdeal-service';

import { FlashdealFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  deleteFlashdealsSuccess,
  searchFlashdealsRequest,
  searchFlashdealsSuccess,
  updateFlashdealsSuccess,
  getTreeFlashdealsSuccess,
  getParentFlashdealsSuccess,
  getFlashdealsPopularSuccess,
  getFlashdealsSuccess,
  getFlashdealByIdSuccess,
  failure,
  getTreeFlashdealsRequest,
} from './reducer';


export function* fetchFlashdeals({ payload = {} }) {
  try {
    const { data } = yield call(flashdealService.findFlashdeals, payload);
    if(data) {
      const { results, pagination} = data;
      yield put(searchFlashdealsSuccess({ flashdeals: results, pagination }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* updateFlashdeals({ payload }) {
  try {
    const result = yield call(flashdealService.updateFlashdeals, payload);
    if (result) {
      yield put(updateFlashdealsSuccess());
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createFlashdeals({ payload }) {
  try {
      let result;
      if (payload.id) {
        result = yield call(flashdealService.updateFlashdeals, { flashdeal: payload, id: payload.id});
      } else {
        result = yield call(flashdealService.createFlashdeals, payload);
      }
    if (result) yield put(FlashdealFormAction.success('app.editSuccess'))
    else {
      const message = 'Add photo failed'
      const formError = new SubmissionError({
        _error: message
      })

      yield put(FlashdealFormAction.failure(formError))
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Save failed'
    })

    yield put(FlashdealFormAction.failure(formError))
  }
}

export function* deleteFlashdeals({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(flashdealService.deleteFlashdeals, id);
    if (result) {
      yield put(searchFlashdealsRequest());
      yield put(deleteFlashdealsSuccess());
      if(cb) cb('success');
    }
  } catch (error) {
    yield put(failure(error));
    if(cb) cb('error');
  }
}

export function* getTreeFlashdeals({ payload = {} }) {
  try {
    const { data } = yield call(flashdealService.getTreeFlashdeals, payload);
    yield put(getTreeFlashdealsSuccess({ trees: data || [] }));
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getParentFlashdeals({ payload = {} }) {
  try {
    const { id } = payload;
    const { data } = yield call(flashdealService.getParentFlashdeals, id);
    yield put(getParentFlashdealsSuccess({ flashdeals: data || [] }));
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getFlashdealsPopular({ payload = {} }) {
  try {
    const { data } = yield call(flashdealService.getFlashdealsPopular);
    if(data && data.results) {
      const { results } = data;
      yield put(getFlashdealsPopularSuccess({ flashdeals: results }));
    } else {
      yield put(getFlashdealsPopularSuccess({ flashdeals: [] }));
    }
  } catch (error) {
    yield put(getFlashdealsPopularSuccess({ flashdeals: [] }));
  }
}

export function* getFlashdeals({ payload = {} }) {
  try {
    const { data } = yield call(flashdealService.getFlashdeals, payload);
    if(data && data.flashdeals) {
      yield put(getFlashdealsSuccess({ flashdeals: data.flashdeals }));
    }

  } catch (error) {
    yield put(failure(error));
  }
}

export function* getFlashdealById({ payload = {} }) {
  try {
    const { data } = yield call(flashdealService.getFlashdealById, payload.id);
    if(data && data._id) {
      yield put(getFlashdealByIdSuccess({ flashdeal: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export default [
  takeLatest(FlashdealFormAction.REQUEST, createFlashdeals),
  takeLatest('SUPPORTS_LIST_SEARCH_REQUEST', fetchFlashdeals),
  takeLatest('SUPPORTS_DELETE_REQUEST', deleteFlashdeals),
  takeLatest('SUPPORTS_UPDATE_REQUEST', updateFlashdeals),
  takeLatest('GET_TREE_SUPPORT_REQUEST', getTreeFlashdeals),
  takeLatest('GET_PARENT_SUPPORT_REQUEST', getParentFlashdeals),
  takeLatest('GET_SUPPORT_POPULAR_REQUEST', getFlashdealsPopular),
  takeLatest('GET_SUPPORT_REQUEST', getFlashdeals),
  takeLatest('GET_SUPPORT_BY_ID_REQUEST', getFlashdealById),
];
