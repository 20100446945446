import axios from '../utils/axiosInstance';

export const searchQuicks = async (params) => {
  try {
    const { data } = await axios.get('/api/quicks', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getQuickById = async (id) => {
  try {
    const { data } = await axios.get(`/api/quick/id/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveQuick = async (body) => {
  try {
    if(body.id) {
      const { data } = await axios.put(`/api/quick/edit/${body.id}`, body);
      return data;
    } else {
      const { data } = await axios.post('/api/quick/add', body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const deleteQuick = async (id) => {
  try {
    const { data } = await axios.delete(`/api/quick/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
