import { call, put, takeLatest } from "redux-saga/effects";
import * as OriginService from "../../services/origin-service";
import { OriginFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchOriginSuccess,
  getOriginByIdSuccess,
  failure
} from "./reducer";

export function* fetchOrigins({ payload }) {
  try {
    const { data } = yield call(OriginService.searchOrigin, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchOriginSuccess({ origins: results, pagination }));
    } else {
      yield put(failure("Search roles failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getOriginById({ payload }) {
  try {
    const { data } = yield call(OriginService.getOriginById, payload);
    if (data) {
      yield put(getOriginByIdSuccess({ origin: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createOrigins ({ payload }) {
  try {
    let result;
    if (payload.id) {
      result = yield call(OriginService.updateOrigin, {
        size: payload,
        id: payload._id
      });
    } else {
      result = yield call(OriginService.createOrigin, payload);
    }

    if (result) {
      yield put(OriginFormAction.success("Lưu thành công"));
    } else {
      const { message } = "Lưu thành thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(OriginFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(OriginFormAction.failure(formError));
  }
}

export function* deleteOrigin({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(OriginService.deleteOrigin, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(OriginFormAction.REQUEST, createOrigins),
  takeLatest("ORIGIN_LIST_SEARCH_REQUEST", fetchOrigins),
  takeLatest("GET_ORIGIN_BY_ID_SEARCH_REQUEST", getOriginById),
  takeLatest("UPDATE_ORIGIN_REQUEST", createOrigins),
  takeLatest("DELETE_ORIGIN_REQUEST", deleteOrigin),
];
