import { createAction, handleActions, combineActions } from 'redux-actions';
// - Actions
export const searchCategoriesRequest = createAction('CATEGORIES_LIST_SEARCH_REQUEST');
export const searchCategoriesSuccess = createAction('CATEGORIES_LIST_SEARCH_SUCCESS');

export const categoryMainRequest = createAction("GET_CATEGORY_MAIN_REQUEST");
export const categoryMainSuccess = createAction("GET_CATEGORY_MAIN_SUCCESS");

export const getParentCategoriesRequest = createAction('GET_PARENT_CATEGORY_REQUEST');
export const getParentCategoriesSuccess = createAction('GET_PARENT_CATEGORY_SUCCESS');

export const getTreeCategoriesRequest = createAction('GET_TREE_CATEGORY_REQUEST');
export const getTreeCategoriesSuccess = createAction('GET_TREE_CATEGORY_SUCCESS');

export const updateCategoryRequest = createAction('UPDATE_CATEGORIE_REQUEST');
export const deleteCategoriesRequest = createAction('CATEGORIES_DELETE_REQUEST');
export const deleteCategoriesSuccess = createAction('CATEGORIES_DELETE_SUCCESS');


export const failure = createAction('CATEGORIES_ACTION_FAILURE');


// - Initial State
export const initialState = {
  categories: [],
  categoryMains: [],
  categoryMainsing: false,
  pagination: {
    size: 1,
    page: 50,
    total: 0
  },
  loading: false,

  trees: [],
  treesing: false,

  parents: [],
  parentsing: false,
};

// - Reducers
const reducer = handleActions({
  [categoryMainRequest]: state => ({
    ...state, categoryMainsing: true
  }),
  [categoryMainSuccess]: (state, { payload: { categories } }) => {
    return { ...state, categoryMains: categories, categoryMainsing: false };
  },

  [searchCategoriesRequest]: (state) => {
    return { ...state, loading: true };
  },
  [searchCategoriesSuccess]: (state, { payload: { categories, pagination } }) => { // eslint-disable-line
    return { ...state, categories: categories, pagination, loading: false };
  },

  [getParentCategoriesRequest]: (state) => {
    return { ...state, parents: [], parentsing: true };
  },
  [getParentCategoriesSuccess]: (state, { payload: { categories } }) => {
    return { ...state, parents: categories, parentsing: false };
  },

  [getTreeCategoriesRequest]: (state) => {
    return { ...state, trees: [], treesing: true };
  },
  [getTreeCategoriesSuccess]: (state, { payload: { trees } }) => {
    return { ...state, trees: trees, treesing: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loadingFetch: false,
    loading: false,
  }),
}, initialState);

export default reducer;
