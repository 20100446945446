import { createAction, handleActions } from 'redux-actions';

// - Actions
export const forgotRequest = createAction('FORGOT_REQUEST');
export const forgotSuccess = createAction('FORGOT_SUCCESS');
export const forgotFailure = createAction('FORGOT_FAILURE');

export const failure = createAction('AUTH_ACTION_FAILURE');


// - Initial State
export const initialState = {
  forgotLoading: false,
  forgotSuccess: false,
  forgotMessage: '',
};

// - Reducers
const reducer = handleActions({
  /** Forgot */
  [forgotRequest]: (state) => {
    return {
      ...state,
      forgotLoading: true,
      forgotSuccess: false,
      forgotMessage: '',
    };
  },

  [forgotSuccess]: (state) => {
    return {
      ...state,
      forgotLoading: false,
      forgotSuccess: true,
      forgotMessage: '',
    };
  },
  [failure]: (state, action) => ({
    ...state,
    forgotLoading: false,
    forgotSuccess: false,
    forgotMessage: '',
  }),
}, initialState);

export default reducer;
