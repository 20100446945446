import axios from '../utils/axiosInstance';

export const searchKeywords = async (params) => {
  try {
    const { data } = await axios.get('/api/keywords', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getKeywordById = async (id) => {
  try {
    const { data } = await axios.get(`/api/keyword/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createKeyword = async (body) => {
  try {
    const { data } = await axios.post('/api/keyword', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const copyKeyword = async (body) => {
  try {
    const { data } = await axios.post(`/api/keyword/copy`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateKeyword = async (body) => {
  try {
    const { data } = await axios.put(`/api/keyword/${body.id}`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteKeyword = async (id) => {
  try {
    const { data } = await axios.delete(`/api/keyword/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
