import axios from "../utils/axiosInstance";
import Auth from "../storage/Auth";

export const searchBrand = async params => {
  try {
    const { data } = await axios.get("/api/brand/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getBrandById = async id => {
  try {
    const { data } = await axios.get(`/api/brand/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveBrand = async (body) => {
  try {
    if(body.id) {
      const { data } = await axios.put("/api/brand/update", body);
      return data;
    } else {
      const { data } = await axios.post("/api/brand/create", body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};


export const deleteBrand = async id => {
  try {
    const { data } = await axios.delete(`/api/brand/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
