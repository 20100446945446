import axios from '../utils/axiosInstance';

export const seachCategories = async (params) => {
  try {
    const { data } = await axios.get('/api/categories', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchTopCategories = async params => {
  try {
    const { data } = await axios.get("/api/category/tops", { params });
    return data || [];
  } catch (error) {
    throw error;
  }
};

export const getCategories = async (params) => {
  try {
    const { data } = await axios.get('/api/category/get', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getParentCategories = async (params) => {
  try {
    const { data } = await axios.get(`/api/category/type`, {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTreeCategories = async (params) => {
  try {
    const { data } = await axios.get(`/api/category/tree`, {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryById = async (id) => {
  try {
    const { data } = await axios.get(`/api/category/id/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCategoryBySlug = async (slug) => {
  try {
    const { data } = await axios.get(`/api/category/slug/${slug}`);
    return data;
  } catch (error) {
    throw error;
  }
};



export const addCategory = async (body) => {
  try {
    const { data } = await axios.post('/api/category', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateCategory = async (body) => {
  try {
    const { data } = await axios.put(`/api/category/${body.id}`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteCategory = async (id) => {
  try {
    const { data } = await axios.delete(`/api/category/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
