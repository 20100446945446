import axios from "../utils/axiosInstance";
import Auth from "../storage/Auth";

export const searchGuide = async params => {
  try {
    const { data } = await axios.get("/api/guide/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchServices = async (params) => {
  try {
    const { data } = await axios.get("/api/guides", {
      params: {...params, category: 'service'}
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchPolicyServices = async (params) => {
  try {
    const { data } = await axios.get("/api/guides", {
      params: {...params, category: 'policy'}
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGuide = async id => {
  try {
    const { data } = await axios.get(`/api/guide/id/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getGuideBySlug = async slug => {
  try {
    const { data } = await axios.get(`/api/guide/slug/${slug}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createGuide = async (guide) => {
  try {
    if (!(guide.url && guide.url.trim())) {
      guide.url = guide.title;
    }
    if (!(guide.meta_title && guide.meta_title.trim())) {
      guide.meta_title = guide.title;
    }
    const { data } = await axios.post("/api/guide", guide);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateGuide = async ({ id, guide }) => {
  try {
    if (!(guide.url && guide.url.trim())) {
      guide.url = guide.title;
    }
    if (!(guide.meta_title && guide.meta_title.trim())) {
      guide.meta_title = guide.title;
    }
    const { data } = await axios.put(`/api/guide/${id}`, guide);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteGuide = async id => {
  try {
    const { data } = await axios.delete(`/api/guide/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
