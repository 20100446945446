import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchOriginRequest = createAction("ORIGIN_LIST_SEARCH_REQUEST");
export const searchOriginSuccess = createAction("ORIGIN_LIST_SEARCH_SUCCESS");

export const getOriginByIdRequest = createAction("GET_ORIGIN_BY_ID_SEARCH_REQUEST");
export const getOriginByIdSuccess = createAction("GET_ORIGIN_BY_ID_SEARCH_SUCCESS");

export const updateOriginRequest = createAction("UPDATE_ORIGIN_REQUEST");
export const updateOriginSuccess = createAction("UPDATE_ORIGIN_SUCCESS");

export const deleteOriginRequest = createAction("DELETE_ORIGIN_REQUEST");

export const failure = createAction("ORIGIN_ACTION_FAILURE");

// - Initial State
export const initialState = {
  origins: [],
  pagination: {
    pages: 1,
    page: 20,
    total: 0
  },
  loading: false,
  origin: null,
  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchOriginRequest]: state => ({
      ...state,
      loading: true
    }),
    [searchOriginSuccess]: (state, { payload: { origins, pagination } }) => ({
      ...state,
      origins: origins,
      pagination: pagination,
      loading: false
    }),

    [getOriginByIdSuccess]: (state, { payload: { origin } }) => ({
      ...state,
      origin: origin,
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
