import { createAction, handleActions, combineActions } from 'redux-actions';
// - Actions
export const searchArticlesRequest = createAction('ARTICLES_LIST_SEARCH_REQUEST');
export const searchArticlesSuccess = createAction('ARTICLES_LIST_SEARCH_SUCCESS');

export const createArticlesRequest = createAction('ARTICLES_CREATE_REQUEST');
export const createArticlesSuccess = createAction('ARTICLES_CREATE_SUCCESS');

export const updateArticlesRequest = createAction('ARTICLES_UPDATE_REQUEST');
export const updateArticlesSuccess = createAction('ARTICLES_UPDATE_SUCCESS');

export const deleteArticlesRequest = createAction('ARTICLES_DELETE_REQUEST');
export const deleteArticlesSuccess = createAction('ARTICLES_DELETE_SUCCESS');

export const getTreeArticlesRequest = createAction('GET_TREE_ARTICLE_REQUEST');
export const getTreeArticlesSuccess = createAction('GET_TREE_ARTICLE_SUCCESS');

export const getParentArticlesRequest = createAction('GET_PARENT_ARTICLE_REQUEST');
export const getParentArticlesSuccess = createAction('GET_PARENT_ARTICLE_SUCCESS');

export const getArticlesPopularRequest = createAction('GET_ARTICLE_POPULAR_REQUEST');
export const getArticlesPopularSuccess = createAction('GET_ARTICLE_POPULAR_SUCCESS');

export const getArticlesRequest = createAction('GET_ARTICLE_REQUEST');
export const getArticlesSuccess = createAction('GET_ARTICLE_SUCCESS');

export const getArticleByIdRequest = createAction('GET_ARTICLE_BY_ID_REQUEST');
export const getArticleByIdSuccess = createAction('GET_ARTICLE_BY_ID_SUCCESS');

export const failure = createAction('ARTICLES_ACTION_FAILURE');


// - Initial State
export const initialState = {
  articles: [],
  pagination: {
    size: 1,
    page: 50,
    total: 0
  },
  loading: false,
  article: null,

  showMessage: false,
  parentArticles: [],
  loadingFetch: false,
  
  mainArticles: [],
  mainLoading: false,
  
  trees: [],
  treesing: false,
  
  populars: [],
  popularsing: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [combineActions(createArticlesRequest, deleteArticlesRequest, updateArticlesRequest, searchArticlesRequest)]: (state) => ({
    ...state, loading: true
  }),
  [searchArticlesSuccess]: (state, { payload: { articles, pagination } }) => {
    return { ...state, articles: articles, pagination, loading: false };
  },
  [combineActions(createArticlesSuccess, deleteArticlesSuccess, updateArticlesSuccess)]: (state) => ({
    ...state, loading: false
  }),

  [getTreeArticlesRequest]: (state) => {
    return { ...state, trees: [], treesing: true };
  },
  [getTreeArticlesSuccess]: (state, { payload: { trees } }) => {
    return { ...state, trees: trees, treesing: false };
  },

  [getParentArticlesRequest]: (state) => {
    return { ...state, parentArticles: [], loadingFetch: true };
  },
  [getParentArticlesSuccess]: (state, { payload: { articles } }) => {
    return { ...state, parentArticles: articles, loadingFetch: false };
  },

  [getArticlesPopularRequest]: (state) => {
    return { ...state, populars: [], popularsing: true };
  },
  [getArticlesPopularSuccess]: (state, { payload: { articles } }) => {
    return { ...state, populars: articles, popularsing: false };
  },

  [getArticlesRequest]: (state) => {
    return { ...state, mainArticles: [], mainLoading: true };
  },
  [getArticlesSuccess]: (state, { payload: { articles } }) => {
    return { ...state, mainArticles: articles, mainLoading: false };
  },

  [getArticleByIdRequest]: (state) => {
    return { ...state, article: null};
  },
  [getArticleByIdSuccess]: (state, { payload: { article } }) => {
    return { ...state, article: article };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loadingFetch: false,
    loading: false,
  }),
}, initialState);

export default reducer;
