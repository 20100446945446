import { call, put, takeLatest } from 'redux-saga/effects';
import * as topicService from '../../services/topic-service';

import { TopicFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  searchTopicSuccess,
  getParentTopicSuccess,
  getMainTopicSuccess,
  failure,
} from './reducer';


export function* fetchTopic({ payload = {} }) {
  try {
    const { data } = yield call(topicService.searchTopics, payload);
    if(data) {
      const { results, pagination} = data;
      yield put(searchTopicSuccess({ topics: results, pagination }));
    } else {
      yield put(searchTopicSuccess({ keywords: [], pagination: { size: 1, page: 50, total: 0 } }));
    }
  } catch (error) {
    yield put(searchTopicSuccess({ keywords: [], pagination: { size: 1, page: 50, total: 0 } }));
  }
}

export function* saveTopic({ payload }) {
  try {
    let result=null;
    if (payload.id) {
      result = yield call(topicService.updateTopic, payload);
    } else {
      result = yield call(topicService.addTopic, payload);
    }
    if (result) {
      yield put(TopicFormAction.success(result))
    } else {
      throw new Error('Lưu từ khóa thất bại!')
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Save failed'
    })

    yield put(TopicFormAction.failure(formError))
  }
}

export function* deleteTopic({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(topicService.deleteTopic, id);
    if (result) {
      if(cb) cb(true);
    }
  } catch (error) {
    if(cb) cb(true);
  }
}

export function* getParentTopic({ payload = {} }) {
  try {
    const { id } = payload;
    const { data } = yield call(topicService.getParentTopics, id);
    yield put(getParentTopicSuccess({ topics: data || [] }));
  } catch (error) {
    yield put(getParentTopicSuccess({ topics: data || [] }));
  }
}

export function* getMainTopic({ payload = {} }) {
  try {
    const { data } = yield call(topicService.getTopic, payload);
    if(data && data.topics) {
      yield put(getMainTopicSuccess({ topics: data.topics }));
    }

  } catch (error) {
    yield put(failure(error));
  }
}


export default [
  takeLatest(TopicFormAction.REQUEST, saveTopic),
  takeLatest('TOPIC_LIST_SEARCH_REQUEST', fetchTopic),
  takeLatest('GET_PARENT_TOPIC_REQUEST', getParentTopic),
  takeLatest('GET_MAIN_TOPIC_REQUEST', getMainTopic),
  takeLatest('TOPIC_DELETE_REQUEST', deleteTopic),
];
