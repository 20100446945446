import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchSellerRequest = createAction("SELLER_LIST_SEARCH_REQUEST");
export const searchSellerSuccess = createAction("SELLER_LIST_SEARCH_SUCCESS");

export const getSellerByIdRequest = createAction("GET_SELLER_BY_ID_SEARCH_REQUEST");
export const getSellerByIdSuccess = createAction("GET_SELLER_BY_ID_SEARCH_SUCCESS");

export const updateSellerRequest = createAction("UPDATE_SELLER_REQUEST");
export const updateSellerSuccess = createAction("UPDATE_SELLER_SUCCESS");

export const deleteSellerRequest = createAction("DELETE_SELLER_REQUEST");

export const failure = createAction("SELLER_ACTION_FAILURE");

// - Initial State
export const initialState = {
  sellers: [],
  pagination: {
    pages: 1,
    page: 20,
    total: 0
  },
  loading: false,
  seller: null,
  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchSellerRequest]: state => ({
      ...state,
      loading: true
    }),
    [searchSellerSuccess]: (state, { payload: { sellers, pagination } }) => ({
      ...state,
      sellers: sellers,
      pagination: pagination,
      loading: false
    }),

    [getSellerByIdSuccess]: (state, { payload: { seller } }) => ({
      ...state,
      seller: seller,
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
