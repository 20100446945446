import { call, put, takeLatest } from "redux-saga/effects";
import * as UnitService from "../../services/unit-service";
import { UnitFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchUnitSuccess,
  getUnitByIdSuccess,
  failure
} from "./reducer";

export function* fetchUnit({ payload }) {
  try {
    const { data } = yield call(UnitService.searchUnit, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchUnitSuccess({ units: results, pagination }));
    } else {
      yield put(failure("Search roles failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getUnitById({ payload }) {
  try {
    const { data } = yield call(UnitService.getUnitById, payload);
    if (data) {
      yield put(getUnitByIdSuccess({ unit: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createUnit ({ payload }) {
  try {
    let result;
    if (payload.id) {
      result = yield call(UnitService.updateUnit, {
        size: payload,
        id: payload._id
      });
    } else {
      result = yield call(UnitService.createUnit, payload);
    }

    if (result) {
      yield put(UnitFormAction.success("Lưu thành công"));
    } else {
      const { message } = "Lưu thành thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(UnitFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(UnitFormAction.failure(formError));
  }
}

export function* updateUnit({ payload }) {
  const { body, cb } = payload;
  try {
    const result = yield call(UnitService.updateUnit, {
      unit: body,
      id: body._id
    });
    if (result) {
      if(cb) cb(true);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}


export function* deleteUnit({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(UnitService.deleteUnit, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(UnitFormAction.REQUEST, createUnit),
  takeLatest("UNIT_LIST_SEARCH_REQUEST", fetchUnit),
  takeLatest("GET_UNIT_BY_ID_SEARCH_REQUEST", getUnitById),
  takeLatest("UPDATE_UNIT_REQUEST", updateUnit),
  takeLatest("DELETE_UNIT_REQUEST", deleteUnit),
];
