import { call, put, takeLatest } from "redux-saga/effects";
import * as ColorService from "../../services/color-service";
import { ColorFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchColorSuccess,
  getColorByIdSuccess,
  failure
} from "./reducer";

export function* fetchColors({ payload }) {
  try {
    const { data } = yield call(ColorService.searchColor, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchColorSuccess({ colors: results, pagination }));
    } else {
      yield put(failure("Search roles failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getColorById({ payload }) {
  try {
    const { data } = yield call(ColorService.getColorById, payload);
    if (data) {
      yield put(getColorByIdSuccess({ color: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createSize({ payload }) {
  try {
    let result;
    if (payload.id) {
      result = yield call(ColorService.updateColor, {
        color: payload,
        id: payload._id
      });
    } else {
      result = yield call(ColorService.createColor, payload);
    }

    if (result) {
      yield put(ColorFormAction.success("Lưu thành công"));
    } else {
      const { message } = "Lưu thành thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(ColorFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(ColorFormAction.failure(formError));
  }
}

export function* updateColor({ payload }) {
  const { body, cb } = payload;
  try {
    const result = yield call(ColorService.updateColor, {
      color: body,
      id: body._id
    });
    if (result) {
      if(cb) cb(true);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}

export function* deleteColor({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(ColorService.deleteColor, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(ColorFormAction.REQUEST, createSize),
  takeLatest("COLOR_LIST_SEARCH_REQUEST", fetchColors),
  takeLatest("GET_COLOR_BY_ID_SEARCH_REQUEST", getColorById),
  takeLatest("UPDATE_COLOR_REQUEST", updateColor),
  takeLatest("DELETE_COLOR_REQUEST", deleteColor),
];
