import { call, put, takeLatest } from "redux-saga/effects";
import * as sliderService from "../../services/slider-service";
import { SliderFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchSliderSuccess,
  multipleSliderSuccess,
  simpleSliderSuccess,
  failure
} from "./reducer";

export function* fetchSlider({ payload }) {
  try {
    const { data } = yield call(sliderService.searchSliders, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchSliderSuccess({ sliders: results, pagination }));
    } else {
      yield put(failure("Search sliders failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchSliderMultiples({ payload }) {
  try {
    const { data } = yield call(sliderService.searchSliders, payload);
    if (data) {
      const { results } = data;
      yield put(multipleSliderSuccess({ sliders: results }));
    } else {
      yield put(failure("Search sliders failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchSliderSimples({ payload }) {
  try {
    const { data } = yield call(sliderService.searchSliders, payload);
    if (data) {
      const { results } = data;
      yield put(simpleSliderSuccess({ sliders: results }));
    } else {
      yield put(failure("Search sliders failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* saveSlider({ payload }) {
  try {
    let result;
    if (payload.id) {
      result = yield call(sliderService.updateSlider, payload);
    } else {
      result = yield call(sliderService.createSlider, payload);
    }

    if (result) {
      yield put(SliderFormAction.success("Lưu thành công"));
    } else {
      const message = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(SliderFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error?.message || 'Lưu thất bại'
    });
    yield put(SliderFormAction.failure(formError));
  }
}

export function* deleteSlider({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(sliderService.deleteSlider, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(SliderFormAction.REQUEST, saveSlider),
  takeLatest("SLIDER_LIST_SEARCH_REQUEST", fetchSlider),
  takeLatest("SLIDER_LIST_MULTIPLE_REQUEST", fetchSliderMultiples),
  takeLatest("SLIDER_LIST_SIMPLE_REQUEST", fetchSliderSimples),
  takeLatest("DELETE_SLIDER_REQUEST", deleteSlider),
];
