import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchMaterialRequest = createAction("MATERIAL_LIST_SEARCH_REQUEST");
export const searchMaterialSuccess = createAction("MATERIAL_LIST_SEARCH_SUCCESS");

export const getMaterialByIdRequest = createAction("GET_MATERIAL_BY_ID_SEARCH_REQUEST");
export const getMaterialByIdSuccess = createAction("GET_MATERIAL_BY_ID_SEARCH_SUCCESS");

export const updateMaterialRequest = createAction("UPDATE_MATERIAL_REQUEST");
export const updateMaterialSuccess = createAction("UPDATE_MATERIAL_SUCCESS");

export const deleteMaterialRequest = createAction("DELETE_MATERIAL_REQUEST");

export const failure = createAction("MATERIAL_ACTION_FAILURE");

// - Initial State
export const initialState = {
  materials: [],
  pagination: {
    pages: 1,
    page: 20,
    total: 0
  },
  loading: false,
  material: null,
  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchMaterialRequest]: state => ({
      ...state,
      loading: true
    }),
    [searchMaterialSuccess]: (state, { payload: { materials, pagination } }) => ({
      ...state,
      materials: materials,
      pagination: pagination,
      loading: false
    }),

    [getMaterialByIdSuccess]: (state, { payload: { material } }) => ({
      ...state,
      material: material,
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
