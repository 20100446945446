import axios from "../utils/axiosInstance";


export const login = async dataLogin => {
  try {
    const { data } = await axios.post("/api/login", dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};
