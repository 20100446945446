import { call, put, takeLatest } from "redux-saga/effects";
import * as priceService from "../../services/price-service";
import { SystemPriceFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchSystemPriceSuccess,
  getSystemPriceSuccess,
  failure
} from "./reducer";

export function* fetchSystemPrice({ payload }) {
  try {
    const { data } = yield call(priceService.searchPrices, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchSystemPriceSuccess({ prices: results, pagination }));
    } else {
      yield put(failure("Search roles failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getSystemPrice({ payload }) {
  try {
    const { data } = yield call(priceService.getPrice, payload);
    if (data) {
      yield put(getSystemPriceSuccess({ price: data }));
    } else {
      yield put(getSystemPriceSuccess({ price: null }));
    }
  } catch (error) {
    yield put(getSystemPriceSuccess({ price: null }));
    yield put(failure(error));
  }
}

export function* getPriceCallback({ payload }) {
  const { body, cb } = payload;
  try {
    const { data } = yield call(priceService.getPrice, body);
    if (data) {
      if (cb) cb("success", data);
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    if (cb) cb("error");
  }
}

export function* saveSystemPrice({ payload }) {
  try {
    let result;
    if (payload._id) {
      result = yield call(priceService.updatePrice, payload);
    } else {
      result = yield call(priceService.createPrice, payload);
    }

    if (result && result.code) {
      yield put(SystemPriceFormAction.success("Lưu thành công"));
    } else {
      const formError = new SubmissionError({
        _error: (result && result.message) || 'Lưu bảng giá thất bại!'
      });
      yield put(SystemPriceFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(SystemPriceFormAction.failure(formError));
  }
}

export function* deleteSystemPrice({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(priceService.deletePrice, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(SystemPriceFormAction.REQUEST, saveSystemPrice),
  takeLatest("SYSTEM_PRICE_LIST_SEARCH_REQUEST", fetchSystemPrice),
  takeLatest("GET_SYSTEM_PRICE_SEARCH_REQUEST", getSystemPrice),
  takeLatest("GET_PRICE_CALLBACK_REQUEST", getPriceCallback),
  takeLatest("DELETE_SYSTEM_PRICE_REQUEST", deleteSystemPrice),
];
