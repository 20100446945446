import axios from '../utils/axiosInstance';

export const searchTopics = async (params) => {
  try {
    const { data } = await axios.get('/api/topic/search', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTopic = async (params) => {
  try {
    const { data } = await axios.get('/api/topic', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getParentTopics = async (parent = null) => {
  try {
    const { data } = await axios.get(`/api/topic/parent/${parent}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTopicById = async (id) => {
  try {
    const { data } = await axios.get(`/api/topic/id/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addTopic = async (body) => {
  try {
    const { data } = await axios.post('/api/topic/add', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateTopic = async (body) => {
  try {
    const { data } = await axios.put(`/api/topic/edit/${body.id}`, body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteTopic = async (id) => {
  try {
    const { data } = await axios.delete(`/api/topic/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};