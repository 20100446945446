import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchGuideRequest = createAction("GUIDES_LIST_SEARCH_REQUEST");
export const searchGuideSuccess = createAction("GUIDES_LIST_SEARCH_SUCCESS");

export const hotGuidesRequest = createAction("GUIDES_LIST_HOT_REQUEST");
export const hotGuidesSuccess = createAction("GUIDES_LIST_HOT_SUCCESS");

export const highGuidesRequest = createAction("GUIDES_LIST_HIGH_REQUEST");
export const highGuidesSuccess = createAction("GUIDES_LIST_HIGH_SUCCESS");

export const getGuideByIdRequest = createAction("GET_GUIDE_BY_ID_REQUEST");
export const getGuideByIdSuccess = createAction("GET_GUIDE_BY_ID_SUCCESS");

export const getGuideRequest = createAction("GET_GUIDE_BY_SLUG_REQUEST");
export const getGuideSuccess = createAction("GET_GUIDE_BY_SLUG_SUCCESS");

export const highServicesRequest = createAction("SERVICE_LIST_HOT_REQUEST");
export const highServicesSuccess = createAction("SERVICE_LIST_HOT_SUCCESS");

export const getPolicyRequest = createAction("GET_POLICY_SERVICE_REQUEST");
export const getPolicySuccess = createAction("GET_POLICY_SERVICE_SUCCESS");

export const deleteGuideRequest = createAction("GUIDE_DELETE_REQUEST");

export const failure = createAction("GUIDE_ACTION_FAILURE");

// - Initial State
export const initialState = {
  guides: [],
  pagination: {
    pages: 1,
    page: 50,
    total: 0
  },
  loading: false,

  hotGuides: [],
  hotArticling: false,

  highGuides: [],
  highArticling: false,

  guide: null,
  articling: false,

  highServices: [],
  highServicesing: false,

  policies: [],
  policing: false,

  error: null,
  completed: false
};

// - Reducers
const reducer = handleActions(
  {
    [searchGuideRequest]: state => {
      return { ...state, loading: true };
    },
    [searchGuideSuccess]: (state, { payload: { guides, pagination } }) => {
      return { ...state, guides: guides, pagination, loading: false };
    },

    [hotGuidesRequest]: state => {
      return { ...state, hotArticling: true };
    },
    [hotGuidesSuccess]: (state, { payload: { guides } }) => {
      return { ...state, hotGuides: guides, hotArticling: false };
    },

    [highGuidesRequest]: state => {
      return { ...state, highArticling: true };
    },
    [highGuidesSuccess]: (state, { payload: { guides } }) => {
      return { ...state, highGuides: guides, highArticling: false };
    },

    [getGuideByIdRequest]: state => {
      return { ...state, guide: null, articling: true };
    },
    [getGuideByIdSuccess]: (state, { payload: { guide } }) => {
      return { ...state, guide: guide, articling: false };
    },

    [getGuideRequest]: state => {
      return { ...state, guide: null, articling: true };
    },
    [getGuideSuccess]: (state, { payload: { guide } }) => {
      return { ...state, guide: guide, articling: false };
    },

    [highServicesRequest]: state => {
      return { ...state, highServicesing: true };
    },
    [highServicesSuccess]: (state, { payload: { guides } }) => {
      return { ...state, highServices: guides, highServicesing: false };
    },

    [getPolicyRequest]: state => {
      return { ...state, policing: true };
    },
    [getPolicySuccess]: (state, { payload: { guides } }) => {
      return { ...state, policies: guides, policing: false };
    },

    [failure]: (state, action) => ({
      ...state,
      error: action.error,
      completed: false
    })
  },
  initialState
);

export default reducer;
