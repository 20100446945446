import { createAction, handleActions, combineActions } from 'redux-actions';
// - Actions
export const searchFlashdealsRequest = createAction('SUPPORTS_LIST_SEARCH_REQUEST');
export const searchFlashdealsSuccess = createAction('SUPPORTS_LIST_SEARCH_SUCCESS');

export const createFlashdealsRequest = createAction('SUPPORTS_CREATE_REQUEST');
export const createFlashdealsSuccess = createAction('SUPPORTS_CREATE_SUCCESS');

export const updateFlashdealsRequest = createAction('SUPPORTS_UPDATE_REQUEST');
export const updateFlashdealsSuccess = createAction('SUPPORTS_UPDATE_SUCCESS');

export const deleteFlashdealsRequest = createAction('SUPPORTS_DELETE_REQUEST');
export const deleteFlashdealsSuccess = createAction('SUPPORTS_DELETE_SUCCESS');

export const getTreeFlashdealsRequest = createAction('GET_TREE_SUPPORT_REQUEST');
export const getTreeFlashdealsSuccess = createAction('GET_TREE_SUPPORT_SUCCESS');

export const getParentFlashdealsRequest = createAction('GET_PARENT_SUPPORT_REQUEST');
export const getParentFlashdealsSuccess = createAction('GET_PARENT_SUPPORT_SUCCESS');

export const getFlashdealsPopularRequest = createAction('GET_SUPPORT_POPULAR_REQUEST');
export const getFlashdealsPopularSuccess = createAction('GET_SUPPORT_POPULAR_SUCCESS');

export const getFlashdealsRequest = createAction('GET_SUPPORT_REQUEST');
export const getFlashdealsSuccess = createAction('GET_SUPPORT_SUCCESS');

export const getFlashdealByIdRequest = createAction('GET_SUPPORT_BY_ID_REQUEST');
export const getFlashdealByIdSuccess = createAction('GET_SUPPORT_BY_ID_SUCCESS');

export const failure = createAction('SUPPORTS_ACTION_FAILURE');


// - Initial State
export const initialState = {
  flashdeals: [],
  pagination: {
    size: 1,
    page: 50,
    total: 0
  },
  loading: false,
  flashdeal: null,

  showMessage: false,
  parentFlashdeals: [],
  loadingFetch: false,
  
  mainFlashdeals: [],
  mainLoading: false,
  
  trees: [],
  treesing: false,
  
  populars: [],
  popularsing: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [combineActions(createFlashdealsRequest, deleteFlashdealsRequest, updateFlashdealsRequest, searchFlashdealsRequest)]: (state) => ({
    ...state, loading: true
  }),
  [searchFlashdealsSuccess]: (state, { payload: { flashdeals, pagination } }) => {
    return { ...state, flashdeals: flashdeals, pagination, loading: false };
  },
  [combineActions(createFlashdealsSuccess, deleteFlashdealsSuccess, updateFlashdealsSuccess)]: (state) => ({
    ...state, loading: false
  }),

  [getTreeFlashdealsRequest]: (state) => {
    return { ...state, trees: [], treesing: true };
  },
  [getTreeFlashdealsSuccess]: (state, { payload: { trees } }) => {
    return { ...state, trees: trees, treesing: false };
  },

  [getParentFlashdealsRequest]: (state) => {
    return { ...state, parentFlashdeals: [], loadingFetch: true };
  },
  [getParentFlashdealsSuccess]: (state, { payload: { flashdeals } }) => {
    return { ...state, parentFlashdeals: flashdeals, loadingFetch: false };
  },

  [getFlashdealsPopularRequest]: (state) => {
    return { ...state, populars: [], popularsing: true };
  },
  [getFlashdealsPopularSuccess]: (state, { payload: { flashdeals } }) => {
    return { ...state, populars: flashdeals, popularsing: false };
  },

  [getFlashdealsRequest]: (state) => {
    return { ...state, mainFlashdeals: [], mainLoading: true };
  },
  [getFlashdealsSuccess]: (state, { payload: { flashdeals } }) => {
    return { ...state, mainFlashdeals: flashdeals, mainLoading: false };
  },

  [getFlashdealByIdRequest]: (state) => {
    return { ...state, flashdeal: null};
  },
  [getFlashdealByIdSuccess]: (state, { payload: { flashdeal } }) => {
    return { ...state, flashdeal: flashdeal };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loadingFetch: false,
    loading: false,
  }),
}, initialState);

export default reducer;
