import { call, put, takeLatest } from 'redux-saga/effects';
import * as CommonService from '../../services/common-service';

import {
  autoCompleteCustomerSuccess,
  autoConsigneeSuccess,
  getTypeExtraServicesSuccess,
  getTypeServicesSuccess,
  getCurrenciesSuccess,
  calculaterSuccess,
  calculaterExtraSuccess,
  failure,
} from './reducer';


export function* autoCompleteCustomer({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.autoCompleteCustomer, payload);
    if(Result) {
      yield put(autoCompleteCustomerSuccess({ customers: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* autoCompleteConsignee({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.autoCompleteConsignee, payload);
    if(Result) {
      yield put(autoConsigneeSuccess({ consignees: Result }));
    }
  } catch (error) {
    yield put(autoConsigneeSuccess({ consignees: [] }));
    yield put(failure(error));
  }
}

export function* getTypeServices({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.getServiceType, payload);
    if(Result) {
      yield put(getTypeServicesSuccess({ services: Result }));
    } else {
      yield put(getTypeServicesSuccess({ services: [] }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getExtraTypeServices() {
  try {
    const { Result } = yield call(CommonService.getExtraServiceType);
    if(Result) {
      yield put(getTypeExtraServicesSuccess({ services: Result }));
    } else {
      yield put(getTypeExtraServicesSuccess({ services: [] }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getCurrency({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.getCurrency, payload);
    if(Result) {
      yield put(getCurrenciesSuccess({ currencies: Result }));
    } else {
      yield put(getCurrenciesSuccess({ currencies: [] }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchCalculater({ payload }) {
  try {
    if(payload) {
      const { Result } = yield call(CommonService.fetchCalculater, payload);
      if(Result) {
        yield put(calculaterSuccess({ calculater: Result }));
      } else {
        yield put(calculaterSuccess({ calculater: null }));
      }
    } else {
      yield put(calculaterSuccess({ calculater: null }));
    }
  } catch (error) {
    yield put(calculaterSuccess({ calculater: null }));
  }
}

export function* fetchCalculaterExtra({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchCalculaterExtra, payload);
    if(Result && Number(Result) > 0) {
      yield put(calculaterExtraSuccess({ extra: Number(Result) }));
    } else {
      yield put(calculaterExtraSuccess({ extra: 0 }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export default [
  takeLatest('AUTO_COMPLETE_CUSTOMER_REQUEST', autoCompleteCustomer),
  takeLatest('AUTO_CONSIGNEE_REQUEST', autoCompleteConsignee),
  takeLatest('GET_TYPE_SERVICE_REQUEST', getTypeServices),
  takeLatest('GET_CURRENCY_REQUEST', getCurrency),
  takeLatest('CALCULATER_REQUEST', fetchCalculater),
  takeLatest('CALCULATER_EXTRA_REQUEST', fetchCalculaterExtra),
  takeLatest('GET_TYPE_EXTRA_SERVICE_REQUEST', getExtraTypeServices)
];
