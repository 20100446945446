// utils/dpop.js

import { SignJWT, exportJWK } from 'jose';
import { getKeyPair } from './crypto';
import { v4 as uuidv4 } from 'uuid'; // Import uuidv4 từ thư viện uuid

export async function createDpopProof(htu, htm) {
    const { privateKey, publicKey } = await getKeyPair();

    const jwt = await new SignJWT({
        htu,
        htm,
        jti: uuidv4(), // Sử dụng uuidv4() để tạo jti
    })
        .setProtectedHeader({
            alg: 'ES256',
            typ: 'dpop+jwt',
            jwk: await exportJWK(publicKey),
        })
        .setIssuedAt()
        .sign(privateKey);

    return jwt;
}
