import axios from "../utils/axiosInstance";
import Auth from "../storage/Auth";

export const searchOrigin = async params => {
  try {
    const { data } = await axios.get("/api/origin-product/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getOriginById = async id => {
  try {
    const { data } = await axios.get(`/api/origin-product/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createOrigin = async size => {
  try {
    const { data } = await axios.post("/api/origin-product/create", size);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateOrigin = async ({ id, size }) => {
  try {
    const { data } = await axios.put(`/api/origin-product/update/${id}`, size);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteOrigin = async id => {
  try {
    const { data } = await axios.delete(`/api/origin-product/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
