// utils/crypto.js

import { generateKeyPair } from 'jose';

let keyPairPromise = null;

export function getKeyPair() {
    if (!keyPairPromise) {
        keyPairPromise = generateKeyPair('ES256');
    }
    return keyPairPromise;
}
