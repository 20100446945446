import { createAction, handleActions, combineActions } from 'redux-actions';
// - Actions
export const searchKeywordsRequest = createAction('KEYWORD_LIST_SEARCH_REQUEST');
export const searchKeywordsSuccess = createAction('KEYWORD_LIST_SEARCH_SUCCESS');

export const updateKeywordRequest = createAction('UPDATE_KEYWORD_REQUEST');
export const copyKeywordsRequest = createAction('KEYWORD_COPY_REQUEST');
export const deleteKeywordsRequest = createAction('KEYWORD_DELETE_REQUEST');
export const deleteKeywordsSuccess = createAction('KEYWORD_DELETE_SUCCESS');

export const failure = createAction('KEYWORD_ACTION_FAILURE');


// - Initial State
export const initialState = {
  keywords: [],
  pagination: {
    size: 1,
    page: 50,
    total: 0
  },
  loading: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [combineActions(deleteKeywordsRequest, searchKeywordsRequest)]: (state) => ({
    ...state, loading: true,
  }),
  [searchKeywordsSuccess]: (state, { payload: { keywords, pagination } }) => { // eslint-disable-line
    return { ...state, keywords: keywords, pagination, loading: false, };
  },
  [combineActions(deleteKeywordsSuccess)]: (state) => ({
    ...state, loading: false,
  }),


  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loadingFetch: false,
    loading: false,
  }),
}, initialState);

export default reducer;
