import axios from "../utils/axiosInstance";
import Auth from "../storage/Auth";

export const searchUnit = async params => {
  try {
    const { data } = await axios.get("/api/unit/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUnitById = async id => {
  try {
    const { data } = await axios.get(`/api/unit/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createUnit = async size => {
  try {
    const { data } = await axios.post("/api/unit/create", size);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUnit = async ({ id, unit }) => {
  try {
    const { data } = await axios.put(`/api/unit/update/${id}`, unit);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteUnit = async id => {
  try {
    const { data } = await axios.delete(`/api/unit/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
