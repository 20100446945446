import { call, put, takeLatest } from "redux-saga/effects";
import * as MaterialService from "../../services/material-service";
import { MaterialFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchMaterialSuccess,
  getMaterialByIdSuccess,
  failure
} from "./reducer";

export function* getMaterials({ payload }) {
  try {
    const { data } = yield call(MaterialService.searchMaterial, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchMaterialSuccess({ materials: results, pagination }));
    } else {
      yield put(failure("Search roles failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getMaterialById({ payload }) {
  try {
    const { data } = yield call(MaterialService.getMaterialById, payload);
    if (data) {
      yield put(getMaterialByIdSuccess({ material: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createMaterial({ payload }) {
  try {
    let result;
    if (payload.id) {
      result = yield call(MaterialService.updateMaterial, {
        material: payload,
        id: payload._id
      });
    } else {
      result = yield call(MaterialService.createMaterial, payload);
    }

    if (result) {
      yield put(MaterialFormAction.success("Lưu thành công"));
    } else {
      const { message } = "Lưu thành thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(MaterialFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(MaterialFormAction.failure(formError));
  }
}

export function* updateMaterial({ payload }) {
  const { body, cb } = payload;
  try {
    const result = yield call(MaterialService.updateMaterial, {
      material: body,
      id: body._id
    });
    if (result) {
      if(cb) cb(true);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}

export function* deleteMaterial({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(MaterialService.deleteMaterial, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(MaterialFormAction.REQUEST, createMaterial),
  takeLatest("MATERIAL_LIST_SEARCH_REQUEST", getMaterials),
  takeLatest("GET_MATERIAL_BY_ID_SEARCH_REQUEST", getMaterialById),
  takeLatest("UPDATE_MATERIAL_REQUEST", updateMaterial),
  takeLatest("DELETE_MATERIAL_REQUEST", deleteMaterial),
];
