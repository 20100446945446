import { createAction, handleActions } from "redux-actions";
// - Actions
export const imagesListRequest = createAction("IMAGES_LIST_REQUEST");
export const imagesListSuccess = createAction("IMAGES_LIST_SUCCESS");

export const uploadRequest = createAction("UPLOAD_IMAGES_REQUEST");
export const createFolderRequest = createAction("CREATE_FOLDER_IMAGES_REQUEST");
export const deleteRequest = createAction("DELETE_IMAGES_REQUEST");

export const failure = createAction("ROLE_ACTION_FAILURE");

// - Initial State
export const initialState = {
  images: [],
  loading: false,
  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [imagesListRequest]: state => ({
      ...state,
      loading: true
    }),
    [imagesListSuccess]: (state, { payload: { images } }) => ({
      ...state,
      images: images,
      loading: false
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
