import { Icons } from './../assets';

export const apiEndpoint = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:7200';
export const domain_img = process.env.NODE_ENV === 'production' ? 'https://click247.vn' : 'http://localhost:7200';
export const hostname = 'http://localhost:5300';
export const title = 'REPORT';
export const ApiUploadImage = `${apiEndpoint}/api/upload/file`;
export const ApiUploadAvatar = `/api/user-info/avatar`;
export const getUrlAvatar = (accounNr) => `${apiEndpoint}/api/user-info/${accounNr}`;
export const URL_ROOT = `${domain_img}/`;
export const auth = {
  appFacebookId: '637698013405871',
  clientIdGoogle: '408369692449-l7jqfvaqe5h0t0qfd8edbfha5ua2hg03.apps.googleusercontent.com'
};

const maxBirthday = () => {
  let maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() - 16)
  return maxDate
}

const minBirthday = () => {
  let maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() - 120)
  return maxDate
}

export const APP_CONFIG = {
  googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBL5OndUGR3MZoGH3slGBrYD1QyNyUMrhk&v=3.exp&libraries=geometry,drawing,places",

  BIRTHDAY: {
    MIN_BIRTHDAY: minBirthday(),
    MAX_BIRTHDAY: maxBirthday()
  },

  PERMISSIONS: [
    { key: 1, text: "Admin", value: "admin" },
    { key: 2, text: "Người dùng", value: "user" }
  ],

  BANNERS: [
    { key: 1, text: "HOME", value: "main" },
    { key: 2, text: "SITE", value: "site" },
    { key: 3, text: "RIGHT", value: "simple" },
    { key: 4, text: "LINE", value: "line" },
  ],
  BANNERS_PAGE: [
    { key: 1, value: "home", text: "Trang chủ" },
    { key: 2, value: "shop", text: "Shop place" },
    { key: 3, value: "auction", text: "Y!Auction" },
    { key: 4, value: "shopping", text: "Y!Shopping" },
    { key: 5, value: "paypay", text: "Y!Paypay" },
    { key: 6, value: "rakuten", text: "Rakute JP" },
    { key: 7, value: "amazon", text: "Amazon JP" },
    { key: 8, value: "mercari", text: "Mercari JP" },
    { key: 9, value: "rakuma", text: "Rakuma JP" },
    { key: 10, value: "ebay-us", text: "Ebay US" },
    { key: 11, value: "jomashop-us", text: "Jomashop US" },
    { key: 12, value: "amazon-us", text: "Amazon US" },
  ],

  PAYMENT_METHOD: [
    {
      value: 'TRANSFER',
      text: 'Chuyển khoản'
    },
    {
      value: 'CASH',
      text: 'Tiền mặt'
    },
    {
      value: 'OTHER',
      text: 'Khác'
    }
  ],
  SITE_TYPE: [
    { value: 'shop-place', text: 'Place shop' },
    { value: 'auction', text: 'Y! Auction JP' },
    { value: 'shopping', text: 'Y! Shopping JP' },
    { value: 'paypay', text: 'Y! PayPay JP' },
    { value: 'rakuten', text: 'Rakuten JP' },
    { value: 'rakuma', text: 'Rakuma JP' },
    { value: 'amazon', text: 'Amazon JP' },
    { value: 'mercari', text: 'Mercari JP' },
    { value: 'ebay-us', text: 'Ebay US' },
    { value: 'amazon-us', text: 'Amazon US' },
    { value: 'jomashop-us', text: 'Jomashop US' }
  ],
  LANGUAGES: [
    {
      code: 'jp',
      name: 'Japan',
      flagIcon: Icons.flag.jp,
      icon: Icons.flag.jp_icon
    },
    {
      code: 'en',
      name: 'English',
      flagIcon: Icons.flag.en,
      icon: Icons.flag.en_icon
    },
    {
      code: 'vi',
      name: 'Tiếng Việt',
      flagIcon: Icons.flag.vi,
      icon: Icons.flag.vi_icon
    },
  ]
}
