import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchSystemPriceRequest = createAction("SYSTEM_PRICE_LIST_SEARCH_REQUEST");
export const searchSystemPriceSuccess = createAction("SYSTEM_PRICE_LIST_SEARCH_SUCCESS");

export const getSystemPriceRequest = createAction("GET_SYSTEM_PRICE_SEARCH_REQUEST");
export const getSystemPriceSuccess = createAction("GET_SYSTEM_PRICE_SEARCH_SUCCESS");

export const getPriceCallbackRequest = createAction("GET_PRICE_CALLBACK_REQUEST");

export const deleteSystemPriceRequest = createAction("DELETE_SYSTEM_PRICE_REQUEST");

export const failure = createAction("SYSTEM_PRICE_ACTION_FAILURE");

// - Initial State
export const initialState = {
  prices: [],
  pagination: {
    pages: 1,
    page: 20,
    total: 0
  },
  loading: false,
  price: null,
  pricesing: false,
  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchSystemPriceRequest]: state => ({
      ...state,
      loading: true
    }),
    [searchSystemPriceSuccess]: (state, { payload: { prices, pagination } }) => ({
      ...state,
      prices: prices,
      pagination: pagination,
      loading: false
    }),

    [getSystemPriceRequest]: state => ({
      ...state,
      pricesing: true
    }),
    [getSystemPriceSuccess]: (state, { payload: { price } }) => ({
      ...state,
      price: price,
      pricesing: false
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
