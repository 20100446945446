
import axios from '../utils/axiosInstance';

export const findArticles = async (params) => {
  try {
    const { data } = await axios.get('/api/article/search', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getArticles = async (params) => {
  try {
    const { data } = await axios.get('/api/articles/get', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTreeArticles = async (payload) => {
  try {
    const { data } = await axios.get(`/api/articles/tree`, {params: payload});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getParentArticles = async (parent = null) => {
  try {
    const { data } = await axios.get(`/api/articles/parent/${parent}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getArticlesPopular = async (parent = null) => {
  try {
    const { data } = await axios.get('/api/articles/popular');
    return data;
  } catch (error) {
    throw error;
  }
};

export const getArticleById = async (id) => {
  try {
    const { data } = await axios.get(`/api/article/id/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getArticleBySlug = async (slug) => {
  try {
    const { data } = await axios.get(`/api/article/slug/${slug}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createArticles = async (article) => {
  try {
    const { data } = await axios.post('/api/article', article);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateArticles = async ({article, id}) => {
  try {
    delete article.id;
    const { data } = await axios.put(`/api/article/${id}`, article);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteArticles = async (id) => {
  try {
    const { data } = await axios.delete(`/api/article/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
