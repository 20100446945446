import axios from "../utils/axiosInstance";

export const searchContacts = async params => {
  try {
    const { data } = await axios.get("/api/contact", { params: params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const createContact = async contact => {
  try {
    const { data } = await axios.post("/api/contact", contact);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateContact = async (contact) => {
  try {
    const { data } = await axios.put(`/api/contact/${contact._id}`, contact);
    return data;
  } catch (error) {
    throw error;
  }
};


export const deleteContact = async id => {
  try {
    const { data } = await axios.delete(`/api/contact/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
