import axios from "../utils/axiosInstance";

export const searchColor = async params => {
  try {
    const { data } = await axios.get("/api/color/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getColorById = async id => {
  try {
    const { data } = await axios.get(`/api/color/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createColor = async color => {
  try {
    const { data } = await axios.post("/api/color/create", color);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateColor = async ({ id, color }) => {
  try {
    const { data } = await axios.put(`/api/color/update/${id}`, color);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteColor = async id => {
  try {
    const { data } = await axios.delete(`/api/color/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
