import axios from "../utils/axiosInstance";

export const searchPrices = async params => {
  try {
    const { data } = await axios.get("/api/prices", { params: params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getPrice = async params => {
  try {
    const { data } = await axios.get("/api/price", { params: params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const createPrice = async price => {
  try {
    const { data } = await axios.post("/api/price", price);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updatePrice = async (price) => {
  try {
    const { data } = await axios.put(`/api/price/${price._id}`, price);
    return data;
  } catch (error) {
    throw error;
  }
};


export const deletePrice = async id => {
  try {
    const { data } = await axios.delete(`/api/price/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
