import axios from "../utils/axiosInstance";
import moment from 'moment';

export const searchUser = async params => {
  try {
    const { data } = await axios.get("/api/users", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserById = async id => {
  try {
    const { data } = await axios.get("/api/users/" + id);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async user => {
  try {
    const { data } = await axios.post("/api/user", user);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async ({ id, user }) => {
  try {
    const { data } = await axios.put(`/api/user/${id}`, user);
    return data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async user => {
  try {
    const { data } = await axios.put(`/api/users/change-password/${user.id}`, user);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async id => {
  try {
    const { data } = await axios.delete(`/api/users/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserByToken = async id => {
  try {
    const { data } = await axios.post("/api/auth/valid/token");
    return data;
  } catch (error) {
    throw error;
  }
};
