import Loadable from 'react-loadable';
import Loading from '../components/Loading'

const LoginPage = Loadable({loader: () => import(/*webpackChunkName:'login'*/'../pages/auth/LoginPage'), loading: Loading});

const MainPage = Loadable({loader: () => import(/*webpackChunkName:'mains'*/'../pages/index'), loading: Loading});
const DashboardPage = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/dashboard/dashboardVM'), loading: Loading});
const CategoriesPage = Loadable({loader: () => import(/*webpackChunkName:'guide'*/'../pages/categories/CategoryVM'), loading: Loading});
const FeedbacksPage = Loadable({loader: () => import(/*webpackChunkName:'guide'*/'../pages/feedback/FeedbackVM'), loading: Loading});
const GuidePage = Loadable({loader: () => import(/*webpackChunkName:'feedbacks'*/'../pages/guides/GuideVM'), loading: Loading});
const GuidesPage = Loadable({loader: () => import(/*webpackChunkName:'guides'*/'../pages/guides/GuidesVM'), loading: Loading});
const ArticlesPage = Loadable({loader: () => import(/*webpackChunkName:'articles'*/'../pages/articles/ArticlesVM'), loading: Loading});
const ArticlePage = Loadable({loader: () => import(/*webpackChunkName:'article'*/'../pages/articles/ArticleVM'), loading: Loading});
const SlidersPage = Loadable({loader: () => import(/*webpackChunkName:'sliders'*/'../pages/banner/BannerVM'), loading: Loading});
const TopicsPage = Loadable({loader: () => import(/*webpackChunkName:'prices'*/'../pages/topic/TopicVM'), loading: Loading});
const KeywordPage = Loadable({loader: () => import(/*webpackChunkName:'prices'*/'../pages/keyword/KeywordVM'), loading: Loading});
const SellerPage = Loadable({loader: () => import(/*webpackChunkName:'prices'*/'../pages/seller/SellerVM'), loading: Loading});
const SetupPage = Loadable({loader: () => import(/*webpackChunkName:'prices'*/'../pages/setups/quick/QuicksVM'), loading: Loading});

const FlashDealsPage = Loadable({loader: () => import(/*webpackChunkName:'articles'*/'../pages/flashdeal/FlashDealsVM'), loading: Loading});
const FlashDealPage = Loadable({loader: () => import(/*webpackChunkName:'article'*/'../pages/flashdeal/FlashDealVM'), loading: Loading});

const SystemsPage = Loadable({loader: () => import(/*webpackChunkName:'system'*/'../pages/system/SystemVM'), loading: Loading});
const RequestPage = Loadable({loader: () => import(/*webpackChunkName:'user'*/'../pages/contact/ContactVM'), loading: Loading});
const UsersPage = Loadable({loader: () => import(/*webpackChunkName:'user'*/'../pages/users/UsersVM'), loading: Loading});
const ProfilePage = Loadable({loader: () => import(/*webpackChunkName:'profile'*/'../pages/user/profile/ProfileVM'), loading: Loading});
const PasswordPage = Loadable({loader: () => import(/*webpackChunkName:'passwords'*/'../pages/user/password/PasswordVM'), loading: Loading});
const ProductPage = Loadable({loader: () => import(/*webpackChunkName:'products'*/'../pages/products'), loading: Loading});
const NewProductPage = Loadable({loader: () => import(/*webpackChunkName:'products'*/'../pages/products/ProductVM'), loading: Loading});
const SizePage = Loadable({loader: () => import(/*webpackChunkName:'products'*/'../pages/size/SizeVM'), loading: Loading});
const UnitPage = Loadable({loader: () => import(/*webpackChunkName:'products'*/'../pages/unit/UnitVM'), loading: Loading});

const OriginPage = Loadable({loader: () => import(/*webpackChunkName:'products'*/'../pages/origin/OriginVM'), loading: Loading});
const BrandPage = Loadable({loader: () => import(/*webpackChunkName:'products'*/'../pages/brand/BrandVM'), loading: Loading});
const ColorPage = Loadable({loader: () => import(/*webpackChunkName:'products'*/'../pages/color/ColorVM'), loading: Loading});

const MaterialPage = Loadable({loader: () => import(/*webpackChunkName:'products'*/'../pages/material/MaterialVM'), loading: Loading});

const routes = [
  { exact: true, path: "/login", component: LoginPage },
  {
    exact: false,
    path: "/",
    component: MainPage,
    private: true,
    routes: [
      { exact: true, path: "/", component: DashboardPage },
      { exact: true, path: "/categories", component: CategoriesPage },
      { exact: true, path: "/topics", component: TopicsPage },
      { exact: true, path: "/guide", component: GuidePage },
      { exact: true, path: "/guide/:id", component: GuidePage },
      { exact: true, path: "/guides", component: GuidesPage },
      { exact: true, path: "/articles", component: ArticlesPage },
      { exact: true, path: "/article", component: ArticlePage },
      { exact: true, path: "/article/:id", component: ArticlePage },
      { exact: true, path: "/flash-deal", component: FlashDealPage },
      { exact: true, path: "/flash-deal/:id", component: FlashDealPage },
      { exact: true, path: "/flash-deals", component: FlashDealsPage },
      // { exact: true, path: "/site", component: SiteStaticPage },
      // { exact: true, path: "/site/:slug", component: SiteStaticPage },
      { exact: true, path: "/feedbacks", component: FeedbacksPage },
      { exact: true, path: "/sliders", component: SlidersPage },
      { exact: true, path: "/keywords", component: KeywordPage },
      { exact: true, path: "/quicks", component: SetupPage },
      { exact: true, path: "/systems", component: SystemsPage },
      { exact: true, path: "/requests", component: RequestPage},
      { exact: true, path: "/users", component: UsersPage},
      { exact: true, path: "/user", component: ProfilePage},
      { exact: true, path: "/user/password", component: PasswordPage},
      { exact: true, path: "/products", component: ProductPage},
      { exact: true, path: "/product", component: NewProductPage},
      { exact: true, path: "/product/:id", component: NewProductPage},
      { exact: true, path: "/size", component: SizePage},
      { exact: true, path: "/calculation-unit", component: UnitPage},
      // { exact: true, path: "/product-origin", component: OriginPage},
      { exact: true, path: "/sellers", component: SellerPage},
      { exact: true, path: "/brands", component: BrandPage},
      { exact: true, path: "/color", component: ColorPage},
      { exact: true, path: "/material", component: MaterialPage},
    ]
  },
];

export default routes
