import axios from "../utils/axiosInstance";
import Auth from "../storage/Auth";

export const searchSeller = async params => {
  try {
    const { data } = await axios.get("/api/seller/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSellerById = async id => {
  try {
    const { data } = await axios.get(`/api/seller/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveSeller = async (body) => {
  try {
    if(body.id) {
      const { data } = await axios.put("/api/seller/update", body);
      return data;
    } else {
      const { data } = await axios.post("/api/seller/create", body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};


export const deleteSeller = async id => {
  try {
    const { data } = await axios.delete(`/api/seller/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
