import { call, put, takeLatest } from 'redux-saga/effects';
import * as quickService from '../../services/setup-service';

import { QuickFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  searchQuickSuccess,
} from './reducer';


export function* fetchQuicks({ payload = {} }) {
  try {
    const { data } = yield call(quickService.searchQuicks, payload);
    if(data) {
      const { results, pagination} = data;
      yield put(searchQuickSuccess({ quicks: results, pagination }));
    } else {
      yield put(searchQuickSuccess({ quicks: [], pagination: { size: 1, page: 50, total: 0 } }));
    }
  } catch (error) {
    yield put(searchQuickSuccess({ quicks: [], pagination: { size: 1, page: 50, total: 0 } }));
  }
}

export function* saveQuick({ payload }) {
  try {
    let { code } = yield call(quickService.saveQuick, payload);
    if (code) {
      yield put(QuickFormAction.success(true))
    } else {
      throw new Error('Lưu từ khóa thất bại!')
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Save failed'
    })

    yield put(QuickFormAction.failure(formError))
  }
}

export function* deleteQuick({ payload }) {
  const { id, cb } = payload;
  try {
    const { data } = yield call(quickService.deleteQuick, id);
    if (data) {
      if(cb) cb(true);
    } else {
      cb(false);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}


export default [
  takeLatest(QuickFormAction.REQUEST, saveQuick),
  takeLatest('QUICK_SEARCH_REQUEST', fetchQuicks),
  takeLatest('QUICK_DELETE_REQUEST', deleteQuick),
];
