import axios from "../utils/axiosInstance";

export const searchMaterial = async params => {
  try {
    const { data } = await axios.get("/api/material/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMaterialById = async id => {
  try {
    const { data } = await axios.get(`/api/material/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createMaterial = async material => {
  try {
    const { data } = await axios.post("/api/material/create", material);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateMaterial = async ({ id, material }) => {
  try {
    const { data } = await axios.put(`/api/material/update/${id}`, material);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteMaterial = async id => {
  try {
    const { data } = await axios.delete(`/api/material/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
