import { call, put, takeLatest } from "redux-saga/effects";
import * as ProductService from "../../services/product-service";
import { ProductFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  searchProductSuccess,
  createProductSuccess,
  editProductSuccess,
  getProductSuccess,
  failure
} from "./reducer";

export function* fetchProduct({ payload = {} }) {
  try {
    const { data } = yield call(ProductService.searchProduct, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(
        searchProductSuccess({ products: results, pagination: pagination })
      );
    }
  } catch (error) {
    yield put(failure(error));
  }
}


export function* getProductById({ payload = {} }) {
  try {
    const { data } = yield call(ProductService.getProductById, payload);
    if (data) {
      yield put(getProductSuccess({ product: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

// export function* getArticle({ payload }) {
//   try {
//     if(payload) {
//       const { data } = yield call(ArticleService.getArticleBySlug, payload);
//       if (data) {
//         yield put(getArticleSuccess({ article: data }));
//       } else {
//         yield put(getArticleSuccess({ article: null }));
//       }
//     } else {
//       yield put(getArticleSuccess({ article: null }));
//     }
//   } catch (error) {
//     yield put(getArticleSuccess({ article: null }));
//   }
// }

export function* createProduct({ payload }) {
  try {
    let result;
    if (payload._id) {
      result = yield call(ProductService.updateProduct, {
        product: payload,
        id: payload._id
      });
    } else {
      result = yield call(ProductService.createProduct, payload);
    }

    if (result) {
      yield put(ProductFormAction.success("Lưu thành công"));
    } else {
      const { message } = "Lưu thành thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(ProductFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(ProductFormAction.failure(formError));
  }
}

export function* updateProduct({ payload }) {
  const { body, cb } = payload;
  try {
    const result = yield call(ProductService.updateProduct, {
      product: body,
      id: body._id
    });
    if (result) {
      if(cb) cb(true);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}


export function* deleteProduct({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(ProductService.deleteProduct, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(ProductFormAction.REQUEST, createProduct),
  takeLatest("PRODUCT_LIST_SEARCH_REQUEST", fetchProduct),
  takeLatest("GET_PRODUCT_BY_ID_REQUEST", getProductById),
  takeLatest("EDIT_PRODUCT_REQUEST", updateProduct),
  // takeLatest("GET_POLICY_SERVICE_REQUEST", fetchPolicyServices),
  // takeLatest("GET_ARTICLE_BY_SLUG_REQUEST", getArticle),
  // takeLatest("GET_ARTICLE_BY_ID_REQUEST", getArticleById),
  takeLatest("PRODUCT_DELETE_REQUEST", deleteProduct)
];
