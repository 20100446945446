import { call, put, takeLatest } from "redux-saga/effects";
import * as SizeService from "../../services/size-service";
import { SizeFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  getSizeByIdSuccess,
  searchSizeSuccess,
  updateSizeSuccess,
  failure
} from "./reducer";

export function* fetchSize({ payload }) {
  try {
    const { data } = yield call(SizeService.searchSize, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchSizeSuccess({ sizes: results, pagination }));
    } else {
      yield put(failure("Search roles failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getSizeById({ payload }) {
  try {
    const { data } = yield call(SizeService.getSizeById, payload);
    if (data) {
      yield put(getSizeByIdSuccess({ size: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createSize({ payload }) {
  try {
    let result;
    if (payload.id) {
      result = yield call(SizeService.updateSize, {
        size: payload,
        id: payload._id
      });
    } else {
      result = yield call(SizeService.createSize, payload);
    }

    if (result) {
      yield put(SizeFormAction.success("Lưu thành công"));
    } else {
      const { message } = "Lưu thành thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(SizeFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(SizeFormAction.failure(formError));
  }
}

export function* deleteSize({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(SizeService.deleteSize, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export function* updateSize({ payload }) {
  const { body, cb } = payload;
  try {
    const result = yield call(SizeService.updateSize, {
      size: body,
      id: body._id
    });
    if (result) {
      if(cb) cb(true);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}

export default [
  takeLatest(SizeFormAction.REQUEST, createSize),
  takeLatest("SIZE_LIST_SEARCH_REQUEST", fetchSize),
  takeLatest("GET_SIZE_BY_ID_SEARCH_REQUEST", getSizeById),
  takeLatest("UPDATE_SIZE_REQUEST", updateSize),
  takeLatest("DELETE_SIZE_REQUEST", deleteSize),
];
