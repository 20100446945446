
import axios from '../utils/axiosInstance';

export const getSystem = async () => {
  try {
    const { data } = await axios.get('/api/store');
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateStore = async (store) => {
  try {
    const { data } = await axios.post('/api/store/update', store);
    return data;
  } catch (error) {
    throw error;
  }
};
