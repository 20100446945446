import { call, put, takeLatest } from 'redux-saga/effects';
import * as feedbacksService from '../../services/feedbacks-service';
import { FeedbackFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  deleteFeedbackSuccess,
  searchFeedbacksRequest,
  searchFeedbacksSuccess,
  updateFeedbacksSuccess,
  failure,
} from './reducer';


export function* fetchFeedbacks({ payload = {} }) {
  try {
    const { data } = yield call(feedbacksService.findFeedbacks, payload);
    if(data) {
      const { results, pagination} = data;
      yield put(searchFeedbacksSuccess({ feedbacks: results, pagination }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* updateFeedback({ payload }) {
  try {
    const result = yield call(feedbacksService.updateFeedback, payload);
    if (result) {
      yield put(updateFeedbacksSuccess());
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* saveFeedback({ payload }) {
  try {
      let result;
      if (payload.id) {
        result = yield call(feedbacksService.updateFeedback, {feedback: payload, id: payload.id});
      } else {
        result = yield call(feedbacksService.createFeedback, payload);
      }
    if (result) yield put(FeedbackFormAction.success('app.editSuccess'))
    else {
      const { message } = 'Add photo failed'
      const formError = new SubmissionError({
        _error: message
      })

      yield put(FeedbackFormAction.failure(formError))
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Save failed'
    })

    yield put(FeedbackFormAction.failure(formError))
  }
}

export function* deleteFeedback({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(feedbacksService.deleteFeedback, id);
    if (result) {
      yield put(searchFeedbacksRequest());
      yield put(deleteFeedbackSuccess());
      if(cb) cb('success');
    }
  } catch (error) {
    yield put(failure(error));
    if(cb) cb('error');
  }
}




export default [
  takeLatest(FeedbackFormAction.REQUEST, saveFeedback),
  takeLatest('FEEDBACKS_LIST_SEARCH_REQUEST', fetchFeedbacks),
  takeLatest('FEEDBACKS_DELETE_REQUEST', deleteFeedback),
  takeLatest('FEEDBACKS_UPDATE_REQUEST', updateFeedback),
];
