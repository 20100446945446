import { createAction, handleActions } from 'redux-actions';
// - Actions
export const searchTopicRequest = createAction('TOPIC_LIST_SEARCH_REQUEST');
export const searchTopicSuccess = createAction('TOPIC_LIST_SEARCH_SUCCESS');

export const getParentTopicRequest = createAction('GET_PARENT_TOPIC_REQUEST');
export const getParentTopicSuccess = createAction('GET_PARENT_TOPIC_SUCCESS');

export const getMainTopicRequest = createAction('GET_MAIN_TOPIC_REQUEST');
export const getMainTopicSuccess = createAction('GET_MAIN_TOPIC_SUCCESS');

export const deleteTopicRequest = createAction('TOPIC_DELETE_REQUEST');
export const deleteTopicSuccess = createAction('TOPIC_DELETE_SUCCESS');

export const failure = createAction('TOPIC_ACTION_FAILURE');

// - Initial State
export const initialState = {
  topics: [],
  pagination: {
    size: 1,
    page: 50,
    total: 0
  },
  loading: false,
  error: null,
  showMessage: false,

  parents: [],
  parentsing: false,

  mains: [],
  mainsing: false,

  topicsProduct: [],
  topicsProducting: false,
};

// - Reducers
const reducer = handleActions({
  [searchTopicRequest]: (state) => {
    return { ...state, loading: true, };
  },
  [searchTopicSuccess]: (state, { payload: { topics, pagination } }) => { // eslint-disable-line
    return { ...state, topics: topics, pagination, loading: false, };
  },

  [getParentTopicRequest]: (state) => {
    return { ...state, parents: [],  parentsing: true, };
  },
  [getParentTopicSuccess]: (state, { payload: { topics } }) => {
    return { ...state, parents: topics, parentsing: false };
  },

  [getMainTopicRequest]: (state) => {
    return { ...state, mains: [], mainsing: true };
  },
  [getMainTopicSuccess]: (state, { payload: { topics } }) => {
    return { ...state, mains: topics, mainsing: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loadingFetch: false,
    loading: false,
  }),
}, initialState);

export default reducer;
