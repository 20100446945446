
import axios from '../utils/axiosInstance';

export const findFlashdeals = async (params) => {
  try {
    const { data } = await axios.get('/api/flash-deal/search', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFlashdeals = async (params) => {
  try {
    const { data } = await axios.get('/api/flash-deals/get', {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTreeFlashdeals = async (payload) => {
  try {
    const { data } = await axios.get(`/api/flash-deals/tree`, {params: payload});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getParentFlashdeals = async (parent = null) => {
  try {
    const { data } = await axios.get(`/api/flash-deals/parent/${parent}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFlashdealsPopular = async (parent = null) => {
  try {
    const { data } = await axios.get('/api/flash-deals/popular');
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFlashdealById = async (id) => {
  try {
    const { data } = await axios.get(`/api/flash-deal/id/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getFlashdealBySlug = async (slug) => {
  try {
    const { data } = await axios.get(`/api/flash-deal/slug/${slug}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createFlashdeals = async (support) => {
  try {
    const { data } = await axios.post('/api/flash-deal', support);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateFlashdeals = async ({support, id}) => {
  try {
    delete support.id;
    const { data } = await axios.put(`/api/flash-deal/${id}`, support);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteFlashdeals = async (id) => {
  try {
    const { data } = await axios.delete(`/api/flash-deal/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
