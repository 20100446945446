import { call, put, takeLatest } from 'redux-saga/effects';
import * as categoryService from '../../services/category-service';
import { CategoryFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  searchCategoriesSuccess,
  getParentCategoriesSuccess,
  getTreeCategoriesSuccess,
  categoryMainSuccess,
} from './reducer';


export function* fetchCategories({ payload = {} }) {
  try {
    const { data } = yield call(categoryService.seachCategories, payload);
    if(data) {
      const { results, pagination} = data;
      yield put(searchCategoriesSuccess({ categories: results, pagination }));
    } else {
      yield put(searchCategoriesSuccess({ categories: [], pagination: { size: 1, page: 50, total: 0 } }));
    }
  } catch (error) {
    yield put(searchCategoriesSuccess({ categories: [], pagination: { size: 1, page: 50, total: 0 } }));
  }
}

export function* getCategoryMain({ payload }) {
  try {
    const { data } = yield call(categoryService.searchTopCategories, payload);
    if (data && data.results) {
      yield put(categoryMainSuccess({ categories: data.results }));
    } else {
      yield put(categoryMainSuccess({ categories: [] }));
    }
  } catch (error) {
    yield put(categoryMainSuccess({ categories: [] }));
  }
}

export function* getParentCategories({ payload = {} }) {
  try {
    const { data } = yield call(categoryService.getParentCategories, payload);
    yield put(getParentCategoriesSuccess({ categories: data || [] }));
  } catch (error) {
    yield put(getParentCategoriesSuccess({ categories: [] }));
  }
}

export function* getTreeCategories({ payload = {} }) {
  try {
    const { data } = yield call(categoryService.getTreeCategories, payload);
    yield put(getTreeCategoriesSuccess({ trees: data || [] }));
  } catch (error) {
    yield put(getTreeCategoriesSuccess({ trees: [] }));
  }
}

export function* saveCategory({ payload }) {
  try {
      let result;
      if (payload.id) {
        result = yield call(categoryService.updateCategory, {category: payload, id: payload.id});
      } else {
        result = yield call(categoryService.addCategory, payload);
      }
    if (result) yield put(CategoryFormAction.success('app.editSuccess'))
    else {
      throw new Error('Lưu danh mục thất bại!')
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Lưu danh mục thất bại!'
    })

    yield put(CategoryFormAction.failure(formError))
  }
}

export function* deleteCategory({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(categoryService.deleteCategory, id);
    if (result) {
      if(cb) cb(true);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}

export function* updateCategory({ payload }) {
  const { body, cb } = payload;
  try {
    const result = yield call(categoryService.updateCategory, { category: body, id: body._id});
    if (result) {
      if(cb) cb(true);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}



export default [
  takeLatest(CategoryFormAction.REQUEST, saveCategory),
  takeLatest('CATEGORIES_LIST_SEARCH_REQUEST', fetchCategories),
  takeLatest('GET_TREE_CATEGORY_REQUEST', getTreeCategories),
  takeLatest('UPDATE_CATEGORIE_REQUEST', updateCategory),
  takeLatest('CATEGORIES_DELETE_REQUEST', deleteCategory),
  takeLatest("GET_CATEGORY_MAIN_REQUEST", getCategoryMain),
  
];
