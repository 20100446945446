import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchContactRequest = createAction("CONTACT_LIST_SEARCH_REQUEST");
export const searchContactSuccess = createAction("CONTACT_LIST_SEARCH_SUCCESS");

export const deleteContactRequest = createAction("DELETE_CONTACT_REQUEST");

export const failure = createAction("ROLE_ACTION_FAILURE");

// - Initial State
export const initialState = {
  contacts: [],
  pagination: {
    pages: 1,
    page: 50,
    total: 0
  },
  loading: false,
  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchContactRequest]: state => ({
      ...state,
      loading: true
    }),
    [searchContactSuccess]: (state, { payload: { contacts, pagination } }) => ({
      ...state,
      contacts: contacts,
      pagination: pagination,
      loading: false
    }),

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
