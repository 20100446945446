import axios from "../utils/axiosInstance";
import Auth from "../storage/Auth";

export const searchProduct = async params => {
  try {
    const { data } = await axios.get("/api/product/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const createProduct = async product => {
  try {
    const { data } = await axios.post("/api/product/create", product);
    return data;
  } catch (error) {
    throw error;
  }
};
export const getProductById = async id => {
  try {
    const { data } = await axios.get(`/api/product/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateProduct = async ({ id, product }) => {
  try {
    const { data } = await axios.put(`/api/product/update/${id}`, product);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteProduct = async id => {
  try {
    const { data } = await axios.delete(`/api/product/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
