import { createAction, handleActions } from 'redux-actions';
// - Actions
export const autoCompleteCustomerRequest = createAction('AUTO_COMPLETE_CUSTOMER_REQUEST');
export const autoCompleteCustomerSuccess = createAction('AUTO_COMPLETE_CUSTOMER_SUCCESS');

export const autoConsigneeRequest = createAction('AUTO_CONSIGNEE_REQUEST');
export const autoConsigneeSuccess = createAction('AUTO_CONSIGNEE_SUCCESS');

export const getTypeServicesRequest = createAction('GET_TYPE_SERVICE_REQUEST');
export const getTypeServicesSuccess = createAction('GET_TYPE_SERVICE_SUCCESS');

export const getTypeExtraServicesRequest = createAction('GET_TYPE_EXTRA_SERVICE_REQUEST');
export const getTypeExtraServicesSuccess = createAction('GET_TYPE_EXTRA_SERVICE_SUCCESS');

export const getCurrenciesRequest = createAction('GET_CURRENCY_REQUEST');
export const getCurrenciesSuccess = createAction('GET_CURRENCY_SUCCESS');

export const calculaterRequest = createAction('CALCULATER_REQUEST');
export const calculaterSuccess = createAction('CALCULATER_SUCCESS');

export const calculaterExtraRequest = createAction('CALCULATER_EXTRA_REQUEST');
export const calculaterExtraSuccess = createAction('CALCULATER_EXTRA_SUCCESS');

export const failure = createAction('ACTION_COMMON_FAILURE');


// - Initial State
export const initialState = {
  customers: [],
  customering: false,
  typeservices: [],
  extraServiceTypes: [],
  typeservicing: false,
  extraServiceTyping: false,

  currencies: [],
  currencing: false,

  consignees: [],
  consigneesing: false,

  calculater: null,
  extra: 0,
  calculating: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [autoCompleteCustomerRequest]: (state) => {
    return {
      ...state,
      customering: true,
    };
  },
  [autoCompleteCustomerSuccess]: (state, { payload: { customers } }) => {
    return {
      ...state,
      customers: customers || [],
      customering: false,
    };
  },

  [autoConsigneeRequest]: (state) => {
    return { ...state, consigneesing: true };
  },
  [autoConsigneeSuccess]: (state, { payload: { consignees } }) => {
    return { ...state, consignees, consigneesing: false};
  },

  [getTypeServicesRequest]: (state) => {
    return {
      ...state,
      typeservicing: true,
    };
  },

  [getTypeServicesSuccess]: (state, { payload: { services } }) => {
    return {
      ...state,
      typeservices: services || [],
      typeservicing: false,
    };
  },

  [getTypeExtraServicesRequest]: (state) => {
    return {
      ...state,
      extraServiceTyping: true,
    };
  },

  [getTypeExtraServicesSuccess]: (state, { payload: { services } }) => {
    return {
      ...state,
      extraServiceTypes: services || [],
      extraServiceTyping: false,
    };
  },

  [getCurrenciesRequest]: (state) => {
    return { ...state,  currencing: true };
  },
  [getCurrenciesSuccess]: (state, { payload: { currencies } }) => {
    return { ...state, currencies: currencies || [], currencing: false };
  },

  [calculaterRequest]: (state) => {
    return { ...state, calculater: null,  calculating: true };
  },
  [calculaterSuccess]: (state, { payload: { calculater } }) => {
    return { ...state, calculater: calculater, calculating: false };
  },

  [calculaterExtraRequest]: (state) => {
    return { ...state, extra: 0,  calculating: true };
  },
  [calculaterExtraSuccess]: (state, { payload: { extra } }) => {
    return { ...state, extra: extra, calculating: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    customering: false,
    calculating: false
  }),
}, initialState);

export default reducer;
