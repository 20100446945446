import { createAction, handleActions } from 'redux-actions';
// - Actions
export const getSystemRequest = createAction('GET_SYSTEM_REQUEST');
export const getSystemSuccess = createAction('GET_SYSTEM_SUCCESS');

export const failure = createAction('SYSTEM_ACTION_FAILURE');


// - Initial State
export const initialState = {
  system: null,
  loading: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [getSystemRequest]: (state) => {
    return { ...state, loading: true };
  },

  [getSystemSuccess]: (state, { payload: { system } }) => {
    return { ...state, system: system, loading: false };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
  }),
}, initialState);

export default reducer;
