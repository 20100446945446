import axios from "../utils/axiosInstance";

export const searchImages = async params => {
  try {
    const { data } = await axios.get("/api/load/images", { params: params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const uploadImage = async image => {
  try {
    const { data } = await axios.post("/api/image/uoload", image);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteImage = async (params) => {
  try {
    const { data } = await axios.post(`/api/image/remove`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const ceateFolderImage = async params => {
  try {
    const { data } = await axios.post("/api/create/folder", params);
    return data;
  } catch (error) {
    throw error;
  }
};
