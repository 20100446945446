import { call, put, takeLatest } from "redux-saga/effects";
import * as SellerService from "../../services/seller-service";
import { SellerFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchSellerSuccess,
  getSellerByIdSuccess,
  failure
} from "./reducer";

export function* fetchSeller({ payload }) {
  try {
    const { data } = yield call(SellerService.searchSeller, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchSellerSuccess({ sellers: results, pagination }));
    } else {
      yield put(failure("Search roles failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getSellerById({ payload }) {
  try {
    const { data } = yield call(SellerService.getSellerById, payload);
    if (data) {
      yield put(getSellerByIdSuccess({ seller: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createSeller({ payload }) {
  try {
    let { data, message } = yield call(SellerService.saveSeller, payload);

    if (data) {
      yield put(SellerFormAction.success("Lưu thành công"));
    } else {
      throw new Error(message || 'Lỗi thất bại')
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error.message || ' Lưu thất bại'
    });
    yield put(SellerFormAction.failure(formError));
  }
}

export function* updateSeller({ payload }) {
  const { body, cb } = payload;
  try {
    const { data } = yield call(SellerService.saveSeller, body);
    if (data) {
      cb(true);
    } else {
      cb(false);
    }
  } catch (error) {
    if(cb) cb(false);
  }
}

export function* deleteSeller({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(SellerService.deleteSeller, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(SellerFormAction.REQUEST, createSeller),
  takeLatest("SELLER_LIST_SEARCH_REQUEST", fetchSeller),
  takeLatest("GET_SELLER_BY_ID_SEARCH_REQUEST", getSellerById),
  takeLatest("UPDATE_SELLER_REQUEST", updateSeller),
  takeLatest("DELETE_SELLER_REQUEST", deleteSeller),
];
