import { call, put, takeLatest } from 'redux-saga/effects';
import * as articleService from '../../services/article-service';

import { ArticleFormAction } from './action';
import { SubmissionError } from 'redux-form';

import {
  deleteArticlesSuccess,
  searchArticlesRequest,
  searchArticlesSuccess,
  updateArticlesSuccess,
  getTreeArticlesSuccess,
  getParentArticlesSuccess,
  getArticlesPopularSuccess,
  getArticlesSuccess,
  getArticleByIdSuccess,
  failure,
  getTreeArticlesRequest,
} from './reducer';


export function* fetchArticles({ payload = {} }) {
  try {
    const { data } = yield call(articleService.findArticles, payload);
    if(data) {
      const { results, pagination} = data;
      yield put(searchArticlesSuccess({ articles: results, pagination }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* updateArticles({ payload }) {
  try {
    const result = yield call(articleService.updateArticles, payload);
    if (result) {
      yield put(updateArticlesSuccess());
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* createArticles({ payload }) {
  try {
      let result;
      if (payload.id) {
        result = yield call(articleService.updateArticles, { article: payload, id: payload.id});
      } else {
        result = yield call(articleService.createArticles, payload);
      }
    if (result) yield put(ArticleFormAction.success('app.editSuccess'))
    else {
      const message = 'Add photo failed'
      const formError = new SubmissionError({
        _error: message
      })

      yield put(ArticleFormAction.failure(formError))
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error:  error.message || 'Save failed'
    })

    yield put(ArticleFormAction.failure(formError))
  }
}

export function* deleteArticles({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(articleService.deleteArticles, id);
    if (result) {
      yield put(searchArticlesRequest());
      yield put(deleteArticlesSuccess());
      if(cb) cb('success');
    }
  } catch (error) {
    yield put(failure(error));
    if(cb) cb('error');
  }
}

export function* getTreeArticles({ payload = {} }) {
  try {
    const { data } = yield call(articleService.getTreeArticles, payload);
    yield put(getTreeArticlesSuccess({ trees: data || [] }));
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getParentArticles({ payload = {} }) {
  try {
    const { id } = payload;
    const { data } = yield call(articleService.getParentArticles, id);
    yield put(getParentArticlesSuccess({ articles: data || [] }));
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getArticlesPopular({ payload = {} }) {
  try {
    const { data } = yield call(articleService.getArticlesPopular);
    if(data && data.results) {
      const { results } = data;
      yield put(getArticlesPopularSuccess({ articles: results }));
    } else {
      yield put(getArticlesPopularSuccess({ articles: [] }));
    }
  } catch (error) {
    yield put(getArticlesPopularSuccess({ articles: [] }));
  }
}

export function* getArticles({ payload = {} }) {
  try {
    const { data } = yield call(articleService.getArticles, payload);
    if(data && data.articles) {
      yield put(getArticlesSuccess({ articles: data.articles }));
    }

  } catch (error) {
    yield put(failure(error));
  }
}

export function* getArticleById({ payload = {} }) {
  try {
    const { data } = yield call(articleService.getArticleById, payload.id);
    if(data && data._id) {
      yield put(getArticleByIdSuccess({ article: data }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export default [
  takeLatest(ArticleFormAction.REQUEST, createArticles),
  takeLatest('ARTICLES_LIST_SEARCH_REQUEST', fetchArticles),
  takeLatest('ARTICLES_DELETE_REQUEST', deleteArticles),
  takeLatest('ARTICLES_UPDATE_REQUEST', updateArticles),
  takeLatest('GET_TREE_ARTICLE_REQUEST', getTreeArticles),
  takeLatest('GET_PARENT_ARTICLE_REQUEST', getParentArticles),
  takeLatest('GET_ARTICLE_POPULAR_REQUEST', getArticlesPopular),
  takeLatest('GET_ARTICLE_REQUEST', getArticles),
  takeLatest('GET_ARTICLE_BY_ID_REQUEST', getArticleById),
];
