import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchProductRequest = createAction("PRODUCT_LIST_SEARCH_REQUEST");
export const searchProductSuccess = createAction("PRODUCT_LIST_SEARCH_SUCCESS");

export const createProductRequest = createAction("CREATE_PRODUCT_REQUEST");
export const createProductSuccess = createAction("CREATE_PRODUCT_SUCCESS");

export const getProductRequest = createAction("GET_PRODUCT_BY_ID_REQUEST");
export const getProductSuccess = createAction("GET_PRODUCT_BY_ID_SUCCESS");

export const editProductRequest = createAction("EDIT_PRODUCT_REQUEST");
export const editProductSuccess = createAction("EDIT_PRODUCT_SUCCESS");

export const deleteProductRequest = createAction("PRODUCT_DELETE_REQUEST");

export const failure = createAction("PRODUCT_ACTION_FAILURE");

// - Initial State
export const initialState = {
  products: [],

  product:null,
  pagination: {
    pages: 1,
    size: 20,
    total: 0
  },
  loading: false,
  error: null,
  completed: false
};

// - Reducers
const reducer = handleActions(
  {
    [searchProductRequest]: state => {
      return {
        ...state,
        loading: true
      };
    },
    [searchProductSuccess]: (state, { payload: { products, pagination } }) => {
      return {
        ...state,
        products: products,
        pagination:pagination,
        loading: false
      };
    },
    [createProductSuccess]: (state, { payload: { product, pagination } }) => {
      return {
        ...state,
        products: [...products,product],
        pagination,
        loading: false
      };
    },
    [editProductSuccess]: (state, { payload: { product, pagination } }) => {
      return {
        ...state,
        loading: false
      };
    },
    [getProductSuccess]: (state, { payload: { product } }) => {
      return {
        ...state,
        product: product,
        loading: false
      };
    },

    [failure]: (state, action) => ({
      ...state,
      error: action.error,
      completed: false
    })
  },
  initialState
);

export default reducer;
