import { call, put, takeLatest } from "redux-saga/effects";
import * as userService from "../../services/users-service";
import Auth from '../../storage/Auth'
import { Modal } from 'antd';
import { UserFormAction, PassFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchUsersSuccess,
  getUserSuccess,
  failure
} from "./reducer";

const notifyUser = () => {
  Modal.error({
    title: 'Lỗi tài khoản!',
    content: 'Đã có lỗi xảy ra khi lấy thông tin tài khoản. Vui lòng liên hệ để được hỗ trợ!',
    okCancel: false,
    okText: 'Thoát',
    onOk() {
      Auth.deauthenticateUser();
      window.location.href = "/login";
    },
    onCancel() {
    }
  });
}

export function* fetchUsers({ payload = {} }) {
  try {
    const { data } = yield call(userService.searchUser, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchUsersSuccess({ users: results, pagination }));
    } else {
      yield put(searchUsersSuccess({ users: [], pagination: {pages: 1, page: 50, total: 0} }));
    }
  } catch (error) {
    yield put(searchUsersSuccess({ users: [], pagination: {pages: 1, page: 50, total: 0} }));
  }
}

export function* getUser() {
  try {
    const { data } = yield call(userService.getUserByToken);
    if (data && data.user) {
      const user = data.user;
      if(user.permissions === 'superadmin' || user.permissions === 'admin') {
        user.admin = true;
        yield put(getUserSuccess({ user }));
      } else {
        Auth.deauthenticateUser();
        window.location.href = "/admin/login";
      }
    } else {
      yield put(getUserSuccess({ user: null }));
      notifyUser();
    }
  } catch (error) {
    yield put(getUserSuccess({ user: null }));
    notifyUser();
  }
}

export function* saveUser({ payload }) {
  try {
    let result;
    if (payload.id) {
      result = yield call(userService.updateUser, {
        user: payload,
        id: payload.id
      });
    } else {
      result = yield call(userService.createUser, payload);
    }

    if (result.code) {
      yield put(UserFormAction.success("Lưu thành công"));
    } else {
      const message = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(UserFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: 'Lưu thất bại!'
    });
    yield put(UserFormAction.failure(formError));
  }
}


export function* changePassword({ payload }) {
  try {
    const result= yield call(userService.changePassword, payload);
    if (result) {
      yield put(PassFormAction.success("Lưu thành công"));
    } else {
      const message = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(PassFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: "Lưu thất bại!"
    });
    yield put(PassFormAction.failure(formError));
  }
}


export function* deleteUser({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(userService.deleteUser, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(UserFormAction.REQUEST, saveUser),
  takeLatest(PassFormAction.REQUEST, changePassword),
  takeLatest("USER_LIST_SEARCH_REQUEST", fetchUsers),
  
  takeLatest("GET_USER_REQUEST", getUser),
  takeLatest("DELETE_USER_REQUEST", deleteUser),
];
