import axios from "../utils/axiosInstance";

export const searchSliders = async params => {
  try {
    const { data } = await axios.get("/api/sliders", { params: params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const createSlider = async slider => {
  try {
    const { data } = await axios.post("/api/slider", slider);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateSlider = async (slider) => {
  try {
    const { data } = await axios.put(`/api/slider/${slider.id}`, slider);
    return data;
  } catch (error) {
    throw error;
  }
};


export const deleteSlider = async id => {
  try {
    const { data } = await axios.delete(`/api/slider/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
