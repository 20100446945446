import axios from "../utils/axiosInstance";
import Auth from "../storage/Auth";

export const searchSize = async params => {
  try {
    const { data } = await axios.get("/api/size/search", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSizeById = async id => {
  try {
    const { data } = await axios.get(`/api/size/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const createSize = async size => {
  try {
    const { data } = await axios.post("/api/size/create", size);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateSize = async ({ id, size }) => {
  try {
    const { data } = await axios.put(`/api/size/update/${id}`, size);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteSize = async id => {
  try {
    const { data } = await axios.delete(`/api/size/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
