import { call, put, takeLatest } from "redux-saga/effects";
import * as contactService from "../../services/contact-service";
import { ContactFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchContactSuccess,
  failure
} from "./reducer";

export function* fetchContact({ payload }) {
  try {
    const { data } = yield call(contactService.searchContacts, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchContactSuccess({ contacts: results, pagination }));
    } else {
      yield put(failure("Search roles failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* saveContact({ payload }) {
  try {
    let result;
    if (payload._id) {
      result = yield call(contactService.updateContact, payload);
    } else {
      result = yield call(contactService.createContact, payload);
    }

    if (result) {
      yield put(ContactFormAction.success("Lưu thành công"));
    } else {
      const { message } = "Lưu thành thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(ContactFormAction.failure(formError));
    }
  } catch (error) {
    const formError = new SubmissionError({
      _error: error
    });
    yield put(ContactFormAction.failure(formError));
  }
}

export function* deleteContact({ payload }) {
  const { id, cb } = payload;
  try {
    const result = yield call(contactService.deleteContact, id);
    if (result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error");
    }
  } catch (error) {
    yield put(failure(error));
    if (cb) cb("error");
  }
}

export default [
  takeLatest(ContactFormAction.REQUEST, saveContact),
  takeLatest("CONTACT_LIST_SEARCH_REQUEST", fetchContact),
  takeLatest("DELETE_CONTACT_REQUEST", deleteContact),
];
