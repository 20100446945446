import { createAction, handleActions, combineActions } from 'redux-actions';
// - Actions
export const searchFeedbacksRequest = createAction('FEEDBACKS_LIST_SEARCH_REQUEST');
export const searchFeedbacksSuccess = createAction('FEEDBACKS_LIST_SEARCH_SUCCESS');

export const createFeedbacksRequest = createAction('FEEDBACKS_CREATE_REQUEST');
export const createFeedbacksSuccess = createAction('FEEDBACKS_CREATE_SUCCESS');

export const deleteFeedbackRequest = createAction('FEEDBACKS_DELETE_REQUEST');
export const deleteFeedbackSuccess = createAction('FEEDBACKS_DELETE_SUCCESS');

export const updateFeedbacksRequest = createAction('FEEDBACKS_UPDATE_REQUEST');
export const updateFeedbacksSuccess = createAction('FEEDBACKS_UPDATE_SUCCESS');

export const failure = createAction('FEEDBACKS_ACTION_FAILURE');


// - Initial State
export const initialState = {
  feedbacks: [],
  pagination: {
    size: 1,
    page: 50,
    total: 0
  },
  loading: false,
  error: null,
  showMessage: false,
};

// - Reducers
const reducer = handleActions({
  [combineActions(createFeedbacksRequest, deleteFeedbackRequest, updateFeedbacksRequest, searchFeedbacksRequest)]: (state) => ({
    ...state,
    loading: true,
  }),
  [searchFeedbacksSuccess]: (state, { payload: { feedbacks, pagination } }) => { // eslint-disable-line
    return {
      ...state,
      feedbacks: feedbacks,
      pagination,
      loading: false,
    };
  },
  [combineActions(createFeedbacksSuccess, deleteFeedbackSuccess, updateFeedbacksSuccess)]: (state) => ({
    ...state,
    loading: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    loadingFetch: false,
    loading: false,
  }),
}, initialState);

export default reducer;
